export const textData = {
  VENTRICULOMEGALY: "Ventriculomegaly",
  DIAGNOSESNAME: "Borderline Ventriculomegaly / Ventriculomegaly",
  NOTCLICKABLESYNDROMES: [
    "Encephalocele",
    "Cystic Hygroma",
    "Diaphragmatic Hernia",
    "C CAM",
    "Skeletal Dysplasia",
    "Body Stalk syndrome",
  ],
};

export const ventriculomegalyDdescription =
  "Many causes can lead to Ventriculomegaly.Most common R Fetal Chromosomal defect, Single gene disorders, Fetal infection, Fetal stroke, and many more conditions can lead to Ventriculomegaly";

export const nuchalFoldThickeningDescription =
  "Nuchal Fold Thickening is diagnosed in the second trimester.It is commonly associated with chromosomal abnormalities, cardiac abnormalities, fetal anemia, fetal infection, and many single-gene disorders. it can also be idiopathic. it can be associated with the following disorders.";

export const fetalGraphReference = {
  P_Acharya: {
    abstract: "Abstract for Indian Population",
    header:
      "EP05.08: Fetal biometry and growth charts: Indian population - Phase II",
    author: "P. Acharya",
    publish: "First published: 15 October 2018 | ",
    url: "https://doi.org/10.1002/uog.19838",
  },
  WHO: {
    abstract: "Abstract for WHO Standards",
    header: "WHO Fetal Growth Charts",
    author: "WHO Multicentre Growth Reference Study Group",
    publish: "First published: January 24, 2017| ",
    url: "https://doi.org/10.1371/journal.pmed.1002220",
  },
  Papageorghious: {
    abstract: "Abstract for Papageorghiou Standards",
    header: "Papageorghiou Fetal Growth Charts",
    author: "Papageorghiou",
    publish: "First published: 2014 | ",
    url: "https://media.tghn.org/medialibrary/2017/03/05_Fetal.pdf",
  },
  Chitty: {
    abstract: "Abstract for Chitty Standards",
    header: "Chitty Fetal Growth Charts",
    author:
      "Lyn S. Chitty, Douglas G. Altman, Annabel Henderson, Stuart Campbell",
    publish: "First published: January 1994 | ",
    url: " https://doi.org/10.1111/j.1471-0528.1994.tb13007.x",
  },
  Snjiders: {
    abstract: "Abstract for Snjiders Standards",
    header: "Snjiders Fetal Growth Charts",
    author: "R. J. M. Snijders, Professor K. H. Nicolaides",
    publish: "1 January 1994 | ",
    url: " https://doi.org/10.1046/j.1469-0705.1994.04010034.x",
  },
  Yudkin: {
    abstract: "Abstract for Yudkin Standards",
    header: "Yudkin Fetal Growth Charts",
    author:
      "P.L. Yudkin, M.  Aboualfa, J.A. Eyre, C.W.G. Redman and A.R. Wilkinson ",
    publish: "First published: 7 September 1986  | ",
    url: "https://doi.org/10.1016/0378-3782(87)90099-5",
  },
};

export const transCerebellarDiameter = {
  Hill_LM: {
    abstract: "Obstet Gynecol. 1990 Jun; 75(6):981-5",
    header:
      "The transverse cerebellar diameter in estimating gestational age in the large for gestational age fetus.",
    publish: "Hill LM, Guzick D, Fries J, Hixson J, Riveloo D",
  },
};

export const ductusVenosus = {
  Kessler: {
    abstract:
      "Ultrasound Obstet Gynecol 2006; 28; 890-898 <br> Published online 9 November 2006 in Wiley InterScience (<a class='text-blue-700' href='https://doi.org/10.1002/uog.3857' target='_blank'>https://doi.org/10.1002/uog.3857</a>)",
    header:
      "Longitudinal reference ranges for dectus venosus flow velocities and waveform indices",
    author: "J. KESSLER, S. RASMUSSEN, M. HANSON and T. KISERUD",
  },
  Parracordero: {
    abstract:
      "Prenat Diagn 2007; 27: 1251–1257<br> Published online 9 November 2006 in Wiley InterScience (<a class='text-blue-700' href=' https://doi.org/10.1002/pd.1868' target='_blank'> https://doi.org/10.1002/pd.1868</a>)",
    header: "Fetal arterial and venous Doppler pulsatility index",
    author:
      "M. Parra-Cordero; C. Lees; H. Missfelder-Lobos; P. Seed; C. Harris ",
  },
};

export const uterineArtery = {
  O_Gemex: {
    abstract:
      "Ultrasound Obstet Gynecol 2008; 32; 128-132 <br> Published online 6 November 2008 in Wiley InterScience (<a class='text-blue-700' href='https://doi.org/10.1002/uog.5315' target='_blank'>https://doi.org/10.1002/uog.5315</a>)",
    header:
      "Reference ranges for uterine artery mean pulsatility index at 11-41 weeks of gestation",
    author:
      "O. GEMEX, F. FIGUERAS, S. FERNANDEZ, M. BENNASAR, J.M. MARTINEZ, B. PUERTO and E. GRATACOS",
  },
};

export const SLVPandAFI = {
  Magann_EF: {
    abstract: "Am J Obstet Gynecol. 2000 Jun; 182(6): 1581-8.",
    header:
      "The amniotic fluid index, single deepest pocket, and two-diameter pocket in normal human pregnacy. (<a class='text-blue-700' href='https://doi.org/10.1067/mob.2000.107325' target='_blank'>https://doi.org/10.1067/mob.2000.107325</a>)",
    author: "Magann EF, Sanderson M, Martin JN, Chauhan S",
  },
};

export const UAandMCAandCPR = {
  Baschat: {
    abstract:
      "Ultrasound Obstet Gynecol 2003; 21: 124–127 Published online 13 January 2003 in Wiley InterScience (<a class='text-blue-700' href='https://doi.org/10.1002/uog.20' target='_blank'>https://doi.org/10.1002/uog.20</a>)",
    header: "The cerebroplacental Doppler ratio revisited",
    author:
      "A. A. BASCHAT* and U. GEMBRUCH†  *Department of Obstetrics, Gynecology and Reproductive Sciences, University of Maryland, Baltimore, MD, USA and †Department of Prenatal Medicine and Obstetrics, Friedrich-Wilhelm University, Bonn, Germany",
  },
  "Ciobanv et al.": {
    abstract: "Ultrasound in Obstetrics & Gynecology 2008",
    header:
      "Fetal Medicine Foundation reference ranges (<a class='text-blue-700' href=' https://doi.org/10.1002/uog.20157' target='_blank'> https://doi.org/10.1002/uog.20157</a>)",
    author:
      "Ciobanu, Anca; Wright, Alan; Syngelaki, Argyro; Wright, David; Akolekar, Ranjit; Nicolaides, Kypros H. ",
  },
  Parracordero: {
    abstract:
      "Prenat Diagn 2007; 27: 1251–1257<br> Published online 9 November 2006 in Wiley InterScience (<a class='text-blue-700' href=' https://doi.org/10.1002/pd.1868' target='_blank'> https://doi.org/10.1002/pd.1868</a>)",
    header: "Fetal arterial and venous Doppler pulsatility index",
    author:
      "M. Parra-Cordero; C. Lees; H. Missfelder-Lobos; P. Seed; C. Harris ",
  },
};

export const nasalbonelength = {
  RB_Prakash: {
    abstract:
      "Foetal Nasal Bone Length Measurement and Evolving Normogram in Southern Indian Population in Normal Pregnancies: A Cross-sectional Study (<a class='text-blue-700' href='https://jcdr.net/article_fulltext.asp?issn=0973-709x&year=2021&volume=15&issue=3&page=TC21&issn=0973-709x&id=14693' target='_blank'>https://jcdr.net/article_fulltext.asp?issn=0973-709x&year=2021&volume=15&issue=3&page=TC21&issn=0973-709x&id=14693</a>)",
    header: "Nasal Bone Length Measurement",
    author: "RB Prakash Jain, Sanket M Kotnis, HN Roopa",
  },
};

export const intracranialtranslucencies = {
  Sung_Hee_Yang: {
    abstract:
      "Design of Standard Value Intracranial Translucency of Fetus in Early Pregnancy Using Ultrasound(<a class='text-blue-700' href='https://www.semanticscholar.org/paper/Design-of-Standard-Value-Intracranial-Translucency-Yang-Kim/1cf99b4ab78bcee55f5bdf146eb3156afddfec36/figure/4' target='_blank'>https://www.semanticscholar.org/paper/Design-of-Standard-Value-Intracranial-Translucency-Yang-Kim/1cf99b4ab78bcee55f5bdf146eb3156afddfec36/figure/4</a>)",
    header:
      " Percentile values of intracranial translucency diameter by gestational age ",
    author: "Sung-Hee Yang, changsoos Kim",
  },
};

export const nuchaltranslucencies = {
  E_A_Junior: {
    abstract:
      "Reference values of nuchal translucency thickness in a Brazilian population sample: experience from a single center (<a class='text-blue-700' href='https://www.semanticscholar.org/paper/Reference-values-of-nuchal-translucency-thickness-a-J%C3%BAnior-Pires/4d782984201ebb27766eab6e17adf09261c28f29/figure/2' target='_blank'>https://www.semanticscholar.org/paper/Reference-values-of-nuchal-translucency-thickness-a-J%C3%BAnior-Pires/4d782984201ebb27766eab6e17adf09261c28f29/figure/2</a>)",
    header:
      "Estimated percentiles of nuchal translucency thickness (mm) for crown-rump length (mm)",
    author: "E.A Junior, C.R. Pires, W. Martins, L. Nardozza, S.M.Z Filho",
  },
};
export const crownRumpLength = {
  L_J_SALOMON: {
    abstract: "Ultrasound Obstet Gynecol 2008; 31: 388-396",
    header: "Crown-rump length measurement in early pregnancy",
    author: "L. J. SALOMON, M. DUYME, J. P. BERNARD and Y. VILLE",
  },
};

export const tableHeaderName = {
  bpd: "BIPARIETAL DIAMETER",
  hc: "HEAD CIRCUMFERENCE",
  ac: "ABDOMINAL CIRCUMFERENCE",
  fl: "FEMUR LENGTH",
  efw: "EFW",
  tcd: "TRANS CEREBELLAR DIAMETER",
  dv: "DUCTUS VENPSUS PI",
  uterine: "UTERINE ARTERY",
  slvp: "SINGLE LARGEST VERTICAL POCKET",
  afi: "AMNIOTIC FLUID INDEX",
  ua: "UMBILICAL ARTERY",
  mca: "MIDDLE CEREBRAL ARTERY",
  cpr: "CEREBROPLACENTAL_RATIO",
  nb: "NASCAL BONE LENGTH",
  it: "INTERCRANIAL TRANSLUCENCY",
  nt: "NUCHAL TRANSLUCENCY",
  crl: "CROWN RUMP LENGTH",
};

export const welcomePageContent = {
  welcomeText: "Welcome to ScanOFe",
  tagline:
    "We aim to provide step-by-step guidance to assist you in reaching appropriate diagnosis of IUGR babies and deciding when to deliver.",
  buttonText: "Start Examination",
};

export const clickableDiagnoses = [
  " Hypoplastic / Absent Nasal Bone",
  "Agenesis Of The Corpus Callosum",
  "Borderline Ventriculomegaly / Ventriculomegaly",
  "Increased NT (Nuchal Translucency)",
  "Nuchal Fold Thickening",
];

export const confirmMessage = "Are you sure about updating your information?";

export const chartDataKeys = {
  BPD: {
    P_Acharya: ["C_5th", "C_50th", "C_95th"],
    WHO: ["C_5th", "C_50th", "C_95th"],
  },
  AC: {
    P_Acharya: ["C_3rd", "C_50th", "C_95th"],
    WHO: ["C_5th", "C_50th", "C_95th"],
  },
  HC: {
    P_Acharya: ["C_5th", "C_50th", "C_95th"],
    Chitty: ["C_3rd", "C_50th", "C_97th"],
  },
  FL: {
    P_Acharya: ["C_5th", "C_50th", "C_95th"],
    Chitty: ["C_5th", "C_50th", "C_95th"],
  },
  EFW: {
    P_Acharya: ["C_3rd", "C_50th", "C_95th"],
    WHO: ["C_5th", "C_50th", "C_95th"],
    Yudkin: ["C_3rd", "C_50th", "C_97th"],
  },
  DV: {
    Kessler: ["C_5th", "C_50th", "C_97_Point_5th"],
  },
  UA: {
    Baschat: ["C_Plus_2_SD", "C_Mean", "C_Minus_2_SD"],
    "Ciobanv et al.": ["C_5th", "C_50th", "C_95th"],
    Parracordero: ["C_5th", "C_50th", "C_95th"],
  },
  MCA: {
    Baschat: ["C_Plus_2_SD", "C_Mean", "C_Minus_2_SD"],
    "Ciobanv et al.": ["C_5th", "C_50th", "C_95th"],
    Parracordero: ["C_5th", "C_50th", "C_95th"],
  },
  CPR: {
    Baschat: ["C_Plus_2_SD", "C_Mean", "C_Minus_2_SD"],
  },
  TCD: {
    Hill_LM: ["C_5th_Percentile", "C_50th_Percentile", "C_95th_Percentile"],
  },
  Uterine: {
    O_Gemex: ["C_5th_Centile", "C_50th_Centile", "C_95th_Centile"],
  },
  SLVP: {
    Magann_EF: ["C_5th_Percentile", "C_50th_Percentile", "C_95th_Percentile"],
  },
  AFI: {
    Magann_EF: ["C_5th_Percentile", "C_50th_Percentile", "C_95th_Percentile"],
  },
  NB: {
    RB_Prakash: ["C_5th_Percentile", "C_50th_Percentile", "C_95th_Percentile"],
  },
  IT: {
    Sung_Hee_Yang: [
      "C_5th_Percentile",
      "C_50th_Percentile",
      "C_95th_Percentile",
    ],
  },
  NT: {
    E_A_Junior: ["C_5th_Percentile", "C_50th_Percentile", "C_95th_Percentile"],
  },
  CRL: {
    L_J_SALOMON: ["C_5th_Percentile", "C_50th_Percentile", "C_95th_Percentile"],
  },
};

export const specialReferenceApis = {
  P_Acharya: {
    BPD: "med-fetal-biometry-biparietal-diameters",
    HC: "med-fetal-biometry-head-circumferences",
    AC: "med-fetal-biometry-abdominal-circumferences",
    FL: "med-fetal-biometry-femur-lengths",
    EFW: "med-fetal-biometry-efws",
  },
  Baschat: {
    UA: "med-umbilical-arteries",
    MCA: "med-middle-cerebral-arteries",
    CPR: "med-cerebroplacental-ratios",
  },
  Kessler: {
    DV: "med-ductus-venosus-pi-in-2nd-and-3rd-trimesters",
  },
  Hill_LM: {
    TCD: "med-trans-cerebellar-diameters",
  },
  Magann_EF: {
    SLVP: "med-oligohydramnios-single-lvps",
    AFI: "med-oligohydramnios-amniotic-fluid-indices",
  },
  O_Gemex: {
    Uterine: "med-uterine-artery-mean-pi-95-centiles",
  },
  RB_Prakash: {
    NB: "med-nasal-bone-lengths",
  },
  Sung_Hee_Yang: {
    IT: "med-intracranial-translucencies",
  },
  E_A_Junior: {
    NT: "med-nuchal-translucencies",
  },
  L_J_SALOMON: {
    CRL: "med-nasal-bone-lengths",
  },
};
