import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import useMainState from "../Context/state/useMainState";
import { useNavigate } from "react-router-dom";
import ScanofeLogo from "../assets/ScanOFeMainLogo.png";
// const {
//   isDropdownOpen,
//   setIsDropdownOpen,
//   dropDownRef,
// } = useMainState("app");

const ChartReferenceSelector = () => {
  // Use the same reference data structure from GenerateChart
  const chartReferences = {
    BPD: ["P_Acharya", "WHO", "Papageorghious", "Snjiders"],
    DV: ["Kessler", "Parracordero"],
    AC: ["P_Acharya", "WHO", "Papageorghious", "Snjiders", "Chitty"],
    HC: ["P_Acharya", "Chitty", "Papageorghious", "Snjiders"],
    FL: ["P_Acharya", "Chitty", "Papageorghious", "Snjiders", "WHO"],
    EFW: ["P_Acharya", "WHO", "Yudkin"],
    TCD: ["Hill_LM"],
    Uterine: ["O_Gemex"],
    SLVP: ["Magann_EF"],
    AFI: ["Magann_EF"],
    UA: ["Baschat", "Ciobanv et al.", "Parracordero"],
    MCA: ["Baschat", "Ciobanv et al.", "Parracordero"],
    CPR: ["Baschat"],
    NB: ["RB_Prakash"],
    IT: ["Sung_Hee_Yang"],
    NT: ["E_A_Junior"],
    CRL: ["L_J_SALOMON"],
  };

  const [selectedReferences, setSelectedReferences] = useState({});
  const navigate = useNavigate();

  const { isDropdownOpen, setIsDropdownOpen, dropDownRef } =
    useMainState("app");

  useEffect(() => {
    // Set defaults
    const initialSelections = {};
    Object.keys(chartReferences).forEach((chartType) => {
      const savedReference = localStorage.getItem(`${chartType}_reference`);
      initialSelections[chartType] =
        savedReference || chartReferences[chartType][0];
    });
    setSelectedReferences(initialSelections);
  }, []);

  const handleReferenceChange = (chartType, reference) => {
    setSelectedReferences((prev) => ({
      ...prev,
      [chartType]: reference,
    }));
    localStorage.setItem(`${chartType}_reference`, reference);
  };

  return (
    <>
      <Navbar
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        dropDownRef={dropDownRef}
      />
      <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8 shadow-custom-2">
        {/* <div className="max-w-7xl mx-auto shadow-custom-3 rounded-md"> */}
        {/* Header Section */}
        <div className="bg-[#E5EEF7] rounded-t-lg p-4 flex items-center justify-between relative ">
          {/* <div className="absolute left-4">
            <img 
              src={ScanofeLogo} 
              alt="ScanOFe Logo" 
              className="h-8 w-auto"
            />
          </div> */}

          <div className="flex-1 text-center">
            <h1 className="text-[#03254c] text-xl font-semibold">
              Chart Reference Selection
            </h1>
          </div>

          <button
            onClick={() => navigate(-1)}
            className="absolute right-4 p-2 hover:bg-[#d4e3f3] rounded-full transition-colors duration-200"
            title="Close"
          >
            <svg
              className="w-5 h-5 text-[#03254c]"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Table Section */}
        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Chart Type
                  </th>
                  <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Reference
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Object.entries(chartReferences).map(
                  ([chartType, references]) =>
                    references.map((reference) => (
                      <tr
                        key={`${chartType}-${reference}`}
                        className="hover:bg-gray-50 transition-colors"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <label className="custom-checkbox">
                            <input
                              type="checkbox"
                              id={`${chartType}-${reference}`}
                              checked={
                                selectedReferences[chartType] === reference
                              }
                              onChange={() =>
                                handleReferenceChange(chartType, reference)
                              }
                            />
                            <span className="checkmark"></span>
                            <span>{chartType}</span>
                          </label>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-gray-600">
                          <span className="font-medium">{reference}</span>
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default ChartReferenceSelector;
