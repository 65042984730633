import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import ScanofeLogo from "../assets/ScanOFeMainLogo.png";
import useMainState from "../Context/state/useMainState";
import { useAppState } from "../Context";
import "./guide.css";

// Icons for each step (placeholders - you can replace with actual icons if available)
import { FiSearch, FiUpload, FiEdit, FiBarChart, FiCheckCircle, FiSettings, FiSend } from 'react-icons/fi';

function GuidePage() {
  const { isDropdownOpen, setIsDropdownOpen, dropDownRef } = useMainState("app");
  const [activeStep, setActiveStep] = useState(1);
  const stepRefs = useRef([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  // Check if the device is mobile or tablet
  useEffect(() => {
    const checkDeviceType = () => {
      const width = window.innerWidth;
      setIsMobile(width < 768);
      setIsTablet(width >= 768 && width < 992);
    };
    
    checkDeviceType();
    window.addEventListener('resize', checkDeviceType);
    
    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, []);

  // Initialize the refs for each step
  useEffect(() => {
    stepRefs.current = Array(7).fill().map((_, i) => stepRefs.current[i] || React.createRef());
  }, []);

  // Scroll to the active step when it changes
  useEffect(() => {
    if (stepRefs.current[activeStep - 1]?.current) {
      const yOffset = -120; // Account for fixed header
      const element = stepRefs.current[activeStep - 1].current;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      
      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
  }, [activeStep]);

  const handleClick = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  // Step data with all required information
  const steps = [
    {
      number: 1,
      title: "Select Your Patient",
      description: "Begin by selecting an existing patient from your list or create a new patient profile with all necessary demographic information.",
      icon: <FiSearch className="w-12 h-12 text-[#03254c]" />,
      tip: "Pro tip: Keep your patient database organized by regularly reviewing and updating patient information."
    },
    {
      number: 2,
      title: "Upload Scan Images",
      description: "Upload ultrasound scan images directly from your device. Our system supports various image formats to ensure compatibility with your equipment.",
      icon: <FiUpload className="w-12 h-12 text-[#03254c]" />,
      tip: "Pro tip: Ensure your images are clear and properly labeled before uploading for the best analysis results."
    },
    {
      number: 3,
      title: "Input Measurement Data",
      description: "Enter key measurement data from your ultrasound scan. Our user-friendly interface guides you through the required fields for comprehensive analysis.",
      icon: <FiEdit className="w-12 h-12 text-[#03254c]" />,
      tip: "Pro tip: Double-check all measurements for accuracy before proceeding to ensure reliable results."
    },
    {
      number: 4,
      title: "Generate Analysis Results",
      description: "Our advanced algorithm processes the input data to generate detailed analysis results, including growth charts and percentile rankings.",
      icon: <FiBarChart className="w-12 h-12 text-[#03254c]" />,
      tip: "Pro tip: Compare results with previous scans to track development patterns over time."
    },
    {
      number: 5,
      title: "Review and Validate",
      description: "Carefully review the generated results for accuracy and completeness. Make any necessary adjustments before finalizing the report.",
      icon: <FiCheckCircle className="w-12 h-12 text-[#03254c]" />,
      tip: "Pro tip: Consider having a colleague review complex cases for additional verification."
    },
    {
      number: 6,
      title: "Customize Report Settings",
      description: "Tailor the report to your specific needs by adjusting display settings, choosing which metrics to highlight, and adding custom notes.",
      icon: <FiSettings className="w-12 h-12 text-[#03254c]" />,
      tip: "Pro tip: Save your most frequently used report settings as templates for future use."
    },
    {
      number: 7,
      title: "Save and Share Report",
      description: "Save the finalized report to the patient's file and share it securely with colleagues or patients through our integrated sharing system.",
      icon: <FiSend className="w-12 h-12 text-[#03254c]" />,
      tip: "Pro tip: Use our secure messaging feature to discuss results directly with consulting physicians."
    }
  ];

  return (
    <>
      <div className="navbar-sticky">
        <Navbar
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
          dropDownRef={dropDownRef}
        />
      </div>
      <div className="flex flex-col md:flex-row min-h-[calc(100vh-109px)]" onClick={handleClick}>
        {/* Mobile Step Navigation - Only for small screens */}
        {isMobile && (
          <div className="step-navigation">
            <div className="container mx-auto px-4">
              <div className="step-indicator">
                {steps.map((step) => (
                  <button
                    key={step.number}
                    className={`step-button flex flex-col items-center justify-center mx-2 min-w-[60px] ${
                      activeStep === step.number
                        ? "text-[#03254c] font-poppinsMedium"
                        : "text-gray-500"
                    }`}
                    onClick={() => setActiveStep(step.number)}
                    aria-label={`Go to step ${step.number}: ${step.title}`}
                  >
                    <div className={`flex items-center justify-center w-8 h-8 rounded-full mb-1 ${
                      activeStep === step.number
                        ? "bg-[#03254c] text-white"
                        : "bg-gray-200 text-gray-700"
                    }`}>
                      {step.number}
                    </div>
                    <span className="text-xs whitespace-nowrap">{step.title}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Left navigation column - hidden on mobile, visible on tablet and desktop */}
        <div className={`
          ${isMobile ? 'hidden' : 'sidebar-fixed'} 
          ${isTablet ? 'w-1/3 bg-gray-50 p-4 step-navigation-tablet' : 'md:w-1/4 bg-gray-50'} 
        `}>
          <h2 className="text-xl font-poppinsBold text-[#03254c] mb-6 pl-2">How It Works</h2>
          <div className="space-y-1">
            {steps.map((step) => (
              <div 
                key={step.number} 
                className="relative"
              >
                {/* Connecting line */}
                {step.number < steps.length && (
                  <div className="absolute left-[18px] top-[36px] w-[2px] h-[calc(100%-10px)] bg-gray-300 z-0"></div>
                )}
                <button
                  className={`flex items-center w-full p-3 rounded-md relative z-10 transition-all duration-200 step-button ${
                    activeStep === step.number
                      ? "bg-[#03254c] text-white font-poppinsMedium"
                      : "text-gray-700 hover:bg-gray-200"
                  }`}
                  onClick={() => setActiveStep(step.number)}
                  aria-label={`Go to step ${step.number}: ${step.title}`}
                >
                  <div className={`flex items-center justify-center w-8 h-8 rounded-full mr-3 ${
                    activeStep === step.number
                      ? "bg-white text-[#03254c] font-poppinsBold"
                      : "bg-[#03254c] text-white"
                  }`}>
                    {step.number}
                  </div>
                  <span className="font-poppinsRegular">{step.title}</span>
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Right content area */}
        <div className={`
          ${isMobile ? 'w-full' : ''} 
          ${isTablet ? 'w-2/3 ml-[33.333%]' : 'md:w-3/4 md:ml-[25%]'} 
          p-6
        `}>
          <div className="max-w-3xl mx-auto">
            {/* <h1 className="text-3xl font-poppinsBold text-[#03254c] mb-8 mt-4">How It Works</h1> */}
            
            <div className="space-y-16 mt-10">
              {steps.map((step) => (
                <div 
                  key={step.number}
                  ref={stepRefs.current[step.number - 1]}
                  id={`step-${step.number}`}
                  className={`step-content scroll-mt-20 transition-opacity duration-300 ${
                    activeStep === step.number ? "opacity-100 active" : "opacity-80"
                  }`}
                >
                  <div className="flex flex-col md:flex-row md:items-start">
                    <div className="mr-0 md:mr-6 mb-4 md:mb-0 flex-shrink-0 flex justify-center">
                      <div className="flex items-center justify-center w-16 h-16 rounded-full bg-[#E6EEF8]">
                        {step.icon}
                      </div>
                    </div>
                    <div className={`step-content-text ${isTablet ? 'text-left' : ''}`}>
                      <div className="flex items-baseline justify-center md:justify-start">
                        <span className="text-4xl font-poppinsBold text-[#03254c] mr-2">{step.number}</span>
                        <h2 className="text-2xl font-poppinsBold text-[#03254c]">{step.title}</h2>
                      </div>
                      <p className="mt-3 text-lg text-gray-700 font-poppinsRegular leading-relaxed">
                        {step.description}
                      </p>
                      <div className="mt-4 p-4 bg-[#E6EEF8] rounded-md">
                        <p className="text-sm font-poppinsMedium text-[#03254c]">
                          {step.tip}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Call to Action */}
            <div className="mt-16 mb-12 text-center">
              <h3 className="text-xl font-poppinsBold text-[#03254c] mb-4">Ready to get started?</h3>
              <Link
                to="/examination"
                className="px-8 py-3 font-poppinsBold text-white border rounded bg-[#03254C] inline-block letter-spacing-0.5px border-radius-6px hover:bg-[#01122a] transition-colors duration-200"
              >
                Start New Examination
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GuidePage;