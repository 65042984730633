import * as Tables from './tablesData';


const getInitialReference = (chartType) => {
  const savedReference = localStorage.getItem(`${chartType}_reference`);
  switch (chartType) {
    case "BPD":
      return savedReference || "P_Acharya" ;
    case "HC":
      return savedReference || "P_Acharya" ;
    case "AC":
      return savedReference || "P_Acharya" ;
    case "FL":
      return savedReference || "P_Acharya" ;
    case "EFW":
      return savedReference || "P_Acharya" ;
    case"TCD":
      return savedReference || "Hill_LM" ;
    case "SLVP":
      return savedReference || "Magann_EF" ;
    case "AFI":
      return savedReference || "Magann_EF" ;
    case "DV":
      return savedReference || "Kessler";
    case "UA":
      return savedReference || "Baschat" ;
    case "MCA": 
      return savedReference || "Baschat" ;
    case "CPR":
      return savedReference || "Baschat" ;
    case "Uterine":
      return savedReference || "O_Gemex" ;
    case "NB":
      return savedReference || "RB_Prakash_Jain" ;
    case "IT":
      return savedReference || "Sung_Hee_Yang" ;
    case "NT":
      return savedReference || "E_A_Junior" ;
    case "CRL":
      return savedReference || "L_J_SALOMON" ;
  
  }
};

// Helper function to find closest index in an array
const findClosestWeekIndex = (weeks, targetWeek) => {
  if (!targetWeek) return -1;
  
  return weeks.reduce((closest, current, index) => {
    if (Math.abs(current - targetWeek) < Math.abs(weeks[closest] - targetWeek)) {
      return index;
    }
    return closest;
  }, 0);
};

// Get the appropriate line values based on the measurement type
const getLineValues = (tableData, weekIndex) => {
  // Different tables use different naming conventions for their lines
  const upperLine = 
    tableData.C_95th?.[weekIndex] || 
    tableData['C_97.5th']?.[weekIndex] || 
    tableData['C_Plus_2_SD']?.[weekIndex] || 
    tableData['+2 SD']?.[weekIndex];
    
  const lowerLine = 
    tableData.C_5th?.[weekIndex] || 
    tableData['C_3rd']?.[weekIndex] || 
    tableData['C_Minus_2_SD']?.[weekIndex] || 
    tableData['-2 SD']?.[weekIndex];

  return { upperLine, lowerLine };
};

// Simplified range checker that only checks if value is between lines
export const checkMeasurementRange = (measurement, value, gestationalAge, reference) => {
  if (!value || value === 0) return 'empty';
  
  const table = Tables[measurement];
  if (!table) return 'invalid';

  const tableData = table[reference];
  if (!tableData || !tableData.weeks) return 'invalid';

  const weekIndex = findClosestWeekIndex(tableData.weeks, gestationalAge);
  if (weekIndex === -1) return 'outOfRange';

  const { upperLine, lowerLine } = getLineValues(tableData, weekIndex);
  if (!upperLine || !lowerLine) return 'invalid';

  // Simple range check: between lines = normal, outside = abnormal
  if (value >= lowerLine && value <= upperLine) {
    return 'normal';
  }
  return 'abnormal';
};

// Usage example for specific measurements
export const getRangeStatus = (measurement, value, gestationalAge) => {
  const measurementMap = {
    NT: 'NT',
    CRL: 'CRL',
    BPD: 'Fetal_Biometry_BD',
    HC: 'Fetal_Biometry_HC',
    AC: 'Fetal_Biometry_AC',
    FL: 'Fetal_Biometry_FL',
    EFW: 'Fetal_Biometry_EFW',
    TCD: 'Trans_Cerebellar_Diameter', // Updated to match your table name
    DV: 'DV',
    UA: 'UA',
    MCA: 'MCA',
    CPR: 'CPR',
    NB: 'NB',
    IT: 'IT',
    AFI: 'Oligo_AFI',
    LEFTUterine: 'LEFTUterine',
    RIGHTUterine: 'RIGHTUterine',
    Uterine: 'Uterine_Artery',
    SLVP: 'Oligo_SLVP',
  };

  const tableName = measurementMap[measurement];
  if (!tableName) return 'invalid';

  // Get reference for this measurement
  const reference = getInitialReference(measurement);
  if (!reference) return 'invalid';

  return checkMeasurementRange(tableName, value, gestationalAge, reference);
}; 