import React, { useEffect, useRef, useState } from "react";
import ScanofeLogo from "../assets/ScanOFeMainLogo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAppState } from "../Context";
import useMainState from "../Context/state/useMainState";
import PatientList from "./PatientList";

function Navbar({
  isDropdownOpen,
  setIsDropdownOpen,
  dropDownRef,
  children,
  handlePatientSelection,
  startNewExamination,
}) {
  const [isSelected, setIsSelected] = useState(false);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);
  const [profileName, setProfileName] = useState("John Deo");
  const { setIsAuthenticated, isFreeTrial, getFreeTrial } = useAppState("auth");
  const { getProfileId, getProfile } = useMainState("app");
  const [isPatientListOpen, setIsPatientListOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);
  const location = useLocation();

  const isFormPage = location.pathname === "/examination";

  const navigate = useNavigate();

  useEffect(() => {
    const freeTrial = localStorage.getItem("isFreeTrial");
    const fetchProfileData = async () => {
      try {
        const profileData = await getProfile();
        if (profileData && profileData.name) {
          setProfileName(profileData.name);
        }
      } catch (error) {
        console.error("Error while fetching profile data", error);
      }
    };
    if (!freeTrial) {
      fetchProfileData();
    }
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClick = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const handleSignout = () => {
    setIsAuthenticated(false);
    navigate("/login");
  };

  const handleProfileClick = () => {
    navigate("/profile");
  };

  const handleStartNewExamination = () => {
    navigate("/welcome");
  };

  const handleDisclaimer = () => {
    setIsDisclaimerOpen(true);
  };

  const isPatientListActive = () => {
    return location.pathname === "/patients";
  };

  const isChartreferenceActive = () => {
    return location.pathname === "/chart-reference-selector";
  };

  const isHowItWorksActive = () => {
    return location.pathname === "/how-it-works";
  };

  const handlePatientList = () => {
    navigate("/patients");
  };

  const handlechartReference = () => {
    navigate("/chart-reference-selector");
  };

  const handleHowItWorks = () => {
    navigate("/how-it-works");
  };

  const handleHomeClick = () => {
    isFreeTrial
      ? navigate("/welcome?InteractiveDemo=true")
      : navigate("/welcome");
    setIsDropdownOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Close mobile menu if clicked outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        !event.target.closest(".hamburger-button")
      ) {
        setIsMobileMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileMenuRef]);

  return (
    <>
      <header className="shadow-custom-1" onClick={handleClick}>
        <nav className="flex items-center justify-between max-w-[1860px] mx-auto py-4 px-4">
          <div className="flex items-center gap-10">
            <Link to="/">
              <img
                src={ScanofeLogo}
                className="h-6 sm:h-8"
                alt="Scanofe Logo"
              />
            </Link>

            {/* Desktop Navigation (visible on screens larger than 992px) */}
            <div className="hidden lg:flex items-center space-x-4">
              <button
                className={`
                  text-lg font-poppinsMedium 
                  ${
                    isPatientListActive()
                      ? "text-[#03254c] border-b-2 border-[#03254c] "
                      : "text-[#03254c]"
                  }
                  hover:text-blue-900 
                  transition-colors duration-200 
                  cursor-pointer
                  focus:outline-none
                `}
                onClick={handlePatientList}
              >
                Patients List
              </button>
              <button
                type="submit"
                className={`
                  text-lg font-poppinsMedium 
                  ${
                    isChartreferenceActive()
                      ? "text-[#03254c] border-b-2 border-[#03254c] "
                      : "text-[#03254c]"
                  }
                  hover:text-blue-900 
                  transition-colors duration-200 
                  cursor-pointer
                  focus:outline-none
                `}
                onClick={handlechartReference}
              >
                Chart Reference
              </button>
              <button
                type="submit"
                className={`
                  text-lg font-poppinsMedium 
                  ${
                    isHowItWorksActive()
                      ? "text-[#03254c] border-b-2 border-[#03254c] "
                      : "text-[#03254c]"
                  }
                  hover:text-blue-900 
                  transition-colors duration-200 
                  cursor-pointer
                  focus:outline-none
                `}
                onClick={handleHowItWorks}
              >
                How it Works
              </button>
              <button
                type="submit"
                className={`px-4 py-1
                  text-lg font-poppinsMedium 
                   text-white bg-[#0d1838] border rounded 
                  transition-colors duration-200 
                  ${
                    isFreeTrial
                      ? "cursor-not-allowed opacity-50"
                      : "cursor-pointer"
                  }
                  focus:outline-none
                `}
                onClick={handleStartNewExamination}
                disabled={isFreeTrial}
              >
                New Examination
              </button>
            </div>

            {/* Hamburger Menu Button (only visible between 768px and 992px) */}
            <div className="hidden md:block lg:hidden">
              <button
                onClick={toggleMobileMenu}
                className="text-[#03254c] hover:text-blue-900 focus:outline-none p-2 hamburger-button"
                aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d={isMobileMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                  />
                </svg>
              </button>
            </div>

            {/* Mobile Menu Items (only for tablet view 768px-992px) */}
            <div
              className={`${isMobileMenuOpen ? "block" : "hidden"} absolute top-16 left-0 w-full bg-white shadow-md z-50 md:w-64 border border-gray-200 lg:hidden`}
              ref={mobileMenuRef}
            >
              <div className="flex flex-col py-2">
                <button
                  className={`
                    text-lg font-poppinsMedium px-4 py-3
                    ${isPatientListActive() ? "bg-[#DEEFFF] text-[#03254c]" : "text-[#03254c]"}
                    hover:bg-[#DEEFFF] 
                    transition-colors duration-200 
                    text-left
                    border-b border-gray-100
                  `}
                  onClick={() => {
                    handlePatientList();
                    setIsMobileMenuOpen(false);
                  }}
                >
                  Patients List
                </button>
                <button
                  className={`
                    text-lg font-poppinsMedium px-4 py-3
                    ${isChartreferenceActive() ? "bg-[#DEEFFF] text-[#03254c]" : "text-[#03254c]"}
                    hover:bg-[#DEEFFF] 
                    transition-colors duration-200 
                    text-left
                    border-b border-gray-100
                  `}
                  onClick={() => {
                    handlechartReference();
                    setIsMobileMenuOpen(false);
                  }}
                >
                  Chart Reference
                </button>
                <button
                  className={`
                    text-lg font-poppinsMedium px-4 py-3
                    ${isHowItWorksActive() ? "bg-[#DEEFFF] text-[#03254c]" : "text-[#03254c]"}
                    hover:bg-[#DEEFFF] 
                    transition-colors duration-200 
                    text-left
                    border-b border-gray-100
                  `}
                  onClick={() => {
                    handleHowItWorks();
                    setIsMobileMenuOpen(false);
                  }}
                >
                  How it Works
                </button>
                <button
                  className={`
                    text-lg font-poppinsMedium px-4 py-3
                    ${isFormPage ? "bg-[#DEEFFF] text-[#03254c]" : "text-[#03254c]"}
                    hover:bg-[#DEEFFF] 
                    transition-colors duration-200 
                    text-left
                  `}
                  onClick={() => {
                    if (!isFreeTrial) {
                      handleStartNewExamination();
                      setIsMobileMenuOpen(false);
                    }
                  }}
                  disabled={isFreeTrial}
                >
                  New Examination
                </button>
              </div>
            </div>
          </div>

          {!isFreeTrial && (
            <div className="flex items-center gap-x-3 relative">
              <p className="font-poppinsMedium text-[#03254c] text-lg">
                {profileName}
              </p>

              {children}

              <div onClick={toggleDropdown}>
                <svg
                  className="w-[26px] h-[26px] cursor-pointer"
                  fill="#03254c"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z" />
                </svg>
              </div>

              {isDropdownOpen && (
                <div
                  className="absolute right-0 top-full mt-6 z-20 bg-[#DEEFFF] rounded-lg shadow-xl"
                  ref={dropDownRef}
                >
                  <ul className="">
                    {/* <li
                      className="px-3 py-2 pt-3 cursor-pointer font-poppinsMedium hover:bg-[#03254C] hover:text-white hover:rounded-t-lg"
                      onClick={handleHomeClick}
                    >
                      Home
                    </li> */}
                    <li
                      className="px-3 py-2 cursor-pointer font-poppinsMedium hover:bg-[#03254C] hover:text-white"
                      onClick={() => {
                        setIsDisclaimerOpen(true);
                        setIsDropdownOpen(false);
                      }}
                    >
                      Disclaimer
                    </li>
                    {/* <li
                      className="px-3 py-2 cursor-pointer font-poppinsMedium hover:bg-[#03254C] hover:text-white"
                      onClick={() => {
                        navigate("/chart-reference-selector"); // Navigate to the chart reference selection page
                        setIsDropdownOpen(false); // Close the dropdown
                      }}
                    >
                      Chart Reference
                    </li> */}
                    <li
                      onClick={() => {
                        isFreeTrial ? false : handleProfileClick();
                      }}
                      className={`px-3 py-2 font-poppinsMedium ${
                        isFreeTrial
                          ? "cursor-not-allowed bg-[#bac9d6]"
                          : "cursor-pointer hover:bg-[#03254C] hover:text-white"
                      } `}
                    >
                      Profile
                    </li>
                    <li
                      onClick={() => {
                        isFreeTrial ? false : handleSignout();
                      }}
                      className={`px-3 py-2 font-poppinsMedium ${
                        isFreeTrial
                          ? "cursor-not-allowed bg-[#bac9d6]"
                          : "cursor-pointer hover:bg-[#03254C] hover:text-white"
                      } rounded-b-lg`}
                    >
                      Sign out
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </nav>
      </header>
      {isDisclaimerOpen && (
        <div
          id="default-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex bg-[#ffffffb1] overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow-custom-4">
              {/* Modal header */}
              <div className="flex items-center font-poppinsMedium justify-between px-5 py-2.5 border-b rounded-t ">
                <h3 className="text-xl font-semibold text-gray-900 ">
                  Disclaimer
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                  data-modal-hide="default-modal"
                  onClick={() => {
                    setIsDisclaimerOpen(false);
                  }}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5 space-y-4">
                <p className="text-base font-poppinsMedium leading-relaxed text-gray-500 dark:text-gray-400">
                  ScanOFe is software to aid you in your clinical guidance to
                  reach for fetal diagnosis. We are not legally liable for any
                  clinical error of judgment on the clinician or user side or
                  any possible deficiencies in ScanOFe.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
