import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent } from "@mui/material";

const calculatorOptions = [
  {
    id: 1,
    name: "Trisomy Risk Calculator (FMF)",
    url: "https://fetalmedicine.org/research/assess/trisomies",
  },
  {
    id: 2,
    name: "First Trimester Preeclampsia (FMF)",
    url: "https://fetalmedicine.org/research/assess/preeclampsia/first-trimester",
  },
  {
    id: 3,
    name: "CVR for Congenital Cystic Adenomatoid Malformation",
    url: "https://www.perinatology.com/calculators/CVR.htm",
  },
  {
    id: 4,
    name: "Middle Cerebral Artery for Fetal Anomia",
    url: "https://perinatology.com/calculators/MCA.htm",
  },
  {
    id: 5,
    name: "Lung area to Head circumference Ratio for Congenital diaphragmatic hernia",
    url: "https://perinatology.com/calculators/LHR.htm",
  },
  {
    id: 6,
    name: "Fetal Echocardiogram Z Score Calculator",
    url: "https://perinatology.com/calculators/Fetal%20Echocardiogram%20Z%20Score%20Calculator.html",
  },
  {
    id: 7,
    name: "MCA Peak Systolic Velocity",
    url: "https://perinatology.com/calculators/MCA.htm",
  },
  {
    id: 8,
    name: " Skeletal Survey Calculator 2.1",
    url: "https://perinatology.com/calculators/Skeletal%20Survey.html",
  },
  {
    id: 9,
    name: "Calculation of Twin Birth Weight Discordance",
    url: "https://perinatology.com/calculators/Twin%20Discordance.htm",
  },
  {
    id: 10,
    name: "Fetal Medicine Barcelona",
    url: "https://fetalmedicinebarcelona.org/calc/",
  },
  {
    id: 11,
    name: "SGA management",
    url: "https://fetalmedicine.org/research/manage/sga",
  },
  {
    id: 12,
    name: "Risk for spontaneous birth at <28, <31, <34 or <37 weeks",
    url: "https://fetalmedicine.org/research/assess/preterm/cervix",
  },



];

const RiskCalculator = () => {
  const [openRiskCalculator, setOpenRiskCalculator] = useState(false);

  const handleRiskCalculatorClick = () => {
    setOpenRiskCalculator(true);
  };

  // Compact version for below button
  // const CompactCalculatorList = () => (
  //   <div className="grid grid-cols-1 gap-1 mt-3">
  //     {calculatorOptions.map((calculator) => (
  //       <div
  //         key={calculator.id}
  //         onClick={() => window.open(calculator.url, "_blank")}
  //         className="p-1 bg-white border border-gray-200 rounded-lg cursor-pointer hover:bg-gray-50 transition-all flex justify-between items-center mb-2 py-6"
  //       >
  //         <div>
  //           <h3 className="text-lg font-poppinsMedium text-[#03254c] ml-2">
  //           {calculatorOptions.find((item) => item.id === calculator.id)?.name}
  //           </h3>
  //         </div>
  //         <svg
  //           className="w-4 h-4 text-gray-400"
  //           fill="none"
  //           stroke="currentColor"
  //           viewBox="0 0 24 24"
  //         >
  //           <path
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //             strokeWidth={2}
  //             d="M9 5l7 7-7 7"
  //           />
  //         </svg>
  //       </div>
  //     ))}
  //   </div>
  // );

  return (
    <>
      <div className="mt-2">
        <button
          type="button"
          className={`w-full px-4 py-2 text-lg font-poppinsMedium rounded ${
            openRiskCalculator
              ? " text-[#0d1838] bg-white border rounded"
              : " text-white bg-[#0d1838] border rounded"
          }`}
          onClick={handleRiskCalculatorClick}
        >
          Risk Calculator
        </button>
        {/* <CompactCalculatorList /> */}
      </div>

      <Dialog
        open={openRiskCalculator}
        onClose={() => setOpenRiskCalculator(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent className="p-6">
          <DialogTitle className="text-center font-poppinsMedium text-[#03254c] border-b border-gray-200 pb-3 text-xl">
            Risk Calculator Options
          </DialogTitle>

          <button
            type="button"
            className="absolute z-20 right-5 top-5"
            onClick={() => setOpenRiskCalculator(false)}
          >
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>

          <div className="grid grid-cols-1 gap-4 mt-6">
            {calculatorOptions.map((calculator) => (
              <div
                key={calculator.id}
                onClick={() => window.open(calculator.url, "_blank")}
                className="p-4 bg-white border border-gray-200 rounded-lg cursor-pointer hover:bg-gray-50 transition-all flex justify-between items-center"
              >
                <div>
                  <h3 className="text-lg font-poppinsMedium text-[#03254c]">
                    {calculator.name}
                  </h3>
                  <p className="text-sm text-gray-600">
                    {calculator.description}
                  </p>
                </div>
                <svg
                  className="w-6 h-6 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RiskCalculator;
