import React from "react";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import algoliasearch from "algoliasearch/lite";
import client from "algoliasearch";
import "./search.css";
import Checkbox from "./Checkbox";
import { InstantSearch, SearchBox, useHits, Configure } from "react-instantsearch";
import axios from "axios";
import useMainState from "../Context/state/useMainState";
import { getUniqueValues } from "../utils/myFunction";
import Draggable from "react-draggable";
import SidebarSyndromes2 from "./SidebarSyndromes2";
const searchClient = algoliasearch(
  "5OSPL9PYUS",
  "40cfaf12f496d5dd77226a26499cf09e"
);

function sortHits(data) {
  if (!Array.isArray(data)) return [];
  return [...data].sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    return nameA.localeCompare(nameB);
  });
}

function filterDiagnosesByBodyPart(hits, bodyPart) {
  if (!bodyPart || bodyPart.length === 0) return hits;

  return hits.filter((diagnosis) => {
    return diagnosis.bodyParts?.some((part) => {
      return bodyPart.includes(part.name);
    });
  });
}

const groupChunkedDiagnoses = (hits) => {
  const grouped = hits.reduce((acc, hit) => {
    const key = hit.name;

    if (!acc[key]) {
      // Initialize new group
      acc[key] = {
        ...hit,
        abnormalities: hit.abnormalities ? [...hit.abnormalities] : [],
        objectID: hit.name.toLowerCase().replace(/\s+/g, "-"), // Create stable ID
        isChunked: false, // Mark as no longer chunked after combining
      };
    } else {
      // Merge abnormalities from chunks
      hit.abnormalities?.forEach((abnormality) => {
        const existingAbnormality = acc[key].abnormalities.find(
          (a) => a.name === abnormality.name
        );

        if (existingAbnormality) {
          // Merge syndromes for existing abnormality
          abnormality.syndromes?.forEach((syndrome) => {
            if (
              !existingAbnormality.syndromes.some((s) => s.id === syndrome.id)
            ) {
              existingAbnormality.syndromes.push(syndrome);
            }
          });
        } else {
          // Add new abnormality
          acc[key].abnormalities.push({ ...abnormality });
        }
      });
    }
    return acc;
  }, {});

  return Object.values(grouped);
};

const CustomHits = ({
  checkedItems,
  setCheckedItems,
  firstClicked,
  checkedSyndromes,
  bodyPart,
  setIsOpenModel,
  isOpenModel,
  hitsData,
}) => {
  const { hits: searchHits, results } = useHits();

  // Use search hits if available, otherwise fall back to hitsData
  const currentHits = useMemo(() => {
    const hits = results?.query ? searchHits : hitsData;
    return groupChunkedDiagnoses(hits);
  }, [searchHits, hitsData, results?.query]);

  // Memoize reversed hits once
  const stableHits = useMemo(() => [...currentHits].reverse(), [currentHits]);

  // Memoize filtered diagnoses
  const filteredDiagnoses = useMemo(() => {
    const filtered =
      bodyPart && bodyPart.length > 0
        ? filterDiagnosesByBodyPart(stableHits, bodyPart)
        : stableHits;
    return sortHits(filtered);
  }, [stableHits, bodyPart]);

  // Memoize matched diagnoses
  const matchedDiagnosesBasedOnSyndrome = useMemo(() => {
    if (!filteredDiagnoses || !checkedSyndromes) return [];
    return findMatchedDiagnosesBasedOnSyndrome(
      filteredDiagnoses,
      checkedSyndromes
    ).allPossibleDiagnoses;
  }, [filteredDiagnoses, checkedSyndromes]);

  // Stabilized checkbox change handler
  const handleCheckboxChange = useCallback(
    (hit) => {
      setCheckedItems((prev) => ({
        ...prev,
        [hit.objectID]: {
          ...prev[hit.objectID],
          checked: !prev[hit.objectID]?.checked,
          name: hit.name,
        },
      }));
    },
    [setCheckedItems]
  );

  const renderCheckbox = useCallback(
    (hit) => (
      <Checkbox
        isSyndrome={false}
        key={hit.objectID}
        hit={hit}
        checked={checkedItems[hit.objectID]?.checked || false}
        onCheckboxChange={() => handleCheckboxChange(hit)}
        setIsOpenModel={setIsOpenModel}
        isOpenModel={isOpenModel}
        checkedItems={checkedItems}
        setCheckedItems={setCheckedItems}
      />
    ),
    [checkedItems, handleCheckboxChange, isOpenModel, setIsOpenModel]
  );

  // if (results.nbHits === 0) {
  //   if (!results.query) {
  //     return (
  //       <div className="animate-pulse">
  //         {[...Array(10)].map((_, i) => (
  //          <div key={i} className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4">Hello</div>
  //         ))}
  //       </div>
  //     );
  //   }
  //   return <div className="mx-2 flex justify-center items-center">No Diagnoses found</div>;
  // }

  if (firstClicked.isSyndromeSelected) {
    return (
      <div className="space-y-2">
        {matchedDiagnosesBasedOnSyndrome.map(renderCheckbox)}
      </div>
    );
  }

  if (firstClicked.isDiagnosesSelected || bodyPart !== null) {
    return (
      <div className="space-y-2">
        {filteredDiagnoses.length > 0 ? (
          filteredDiagnoses.map(renderCheckbox)
        ) : (
          <div className="mx-2 flex justify-center items-center">
            No Diagnoses found for selected body part
          </div>
        )}
      </div>
    );
  }

  return null;
};

function findMatchedDiagnosesBasedOnSyndrome(data, checkedSyndromes) {
  let commonDiagnoses = [];

  data.forEach((diagnosis) => {
    let matchedSyndromes = 0;
    diagnosis?.abnormalities?.forEach((abnormality) => {
      abnormality?.syndromes?.forEach((syndrome) => {
        checkedSyndromes.forEach((checked) => {
          if (checked.name === syndrome.name) {
            matchedSyndromes++;
          }
        });
      });
    });

    if (matchedSyndromes === checkedSyndromes.length) {
      commonDiagnoses.push(diagnosis);
    }
  });

  let allPossibleDiagnoses = data.filter((diagnosis) => {
    return diagnosis?.abnormalities?.some((abnormality) => {
      return abnormality?.syndromes?.some((syndrome) => {
        return checkedSyndromes.some((checked) => {
          return checked.name === syndrome.name;
        });
      });
    });
  });

  const filteredAllPossibleDiagnoses = getUniqueValues(
    commonDiagnoses,
    allPossibleDiagnoses
  );

  return {
    commonDiagnoses: commonDiagnoses.reverse(),
    allPossibleDiagnoses: filteredAllPossibleDiagnoses.reverse(),
  };
}

function findUniqueDiagnoses(data, searchNames) {
  const matches = data.filter((item) => searchNames.includes(item.name));

  const diagnoses = matches?.flatMap((item) =>
    item.abnormalities?.flatMap((type) => type.syndromes)
  );

  const diagnosesMap = new Map();
  diagnoses.map((syn) => {
    const key = syn.name;
    if (!diagnosesMap.has(key)) {
      diagnosesMap.set(key, {
        name: syn.name,
        objectID: syn.id,
        description: syn.descriptionAndDefinition,
        slug: syn.slug,
      });
    }
    return diagnosesMap.get(key);
  });

  return [...diagnosesMap.values()];
}

function SidebarDiagnoses({
  checkedItems,
  setCheckedItems,
  setFirstClicked,
  firstClicked,
  checkedSyndromes,
  isDiagnosesModelOpen,
  setIsDiagnosesModelOpen,
  isSyndromeModelOpen, // Add this
  setIsSyndromeModelOpen,
  bodyPart,
  setBodyPart,
}) {
  const [showMessage, setShowMessage] = useState(false);
  const backdropRef = useRef(null);
  const [allDiagnoses, setAllDiagnoses] = useState([]);
  const [commonDiagnoses, setCommonDiagnoses] = useState([]);
  const [hitsData, setHitsData] = useState([]);
  const { diagnosesModel, setDiagnosesModel } = useMainState("app");
  // const [isOpenModel, setIsOpenModel] = useState({
  //   isOpenSidebar: false,
  //   isOpenDiscription: false,
  // });
  const [isShowLoader, setIsShowLoader] = useState(true);
  const [query, setQuery] = useState("");
  const selectedCheckBoxRef = useRef(null);
  const customHitsRef = useRef(null);
  const API_KEY = "d62ae06c780fca089f35d07a53760ef4";
  const APPLICATION_ID = "5OSPL9PYUS";
  // const url =`https://${APPLICATION_ID}-dsn.algolia.net/1/indexes/diseases?page=${page}&hitsPerPage=1000`;

  useEffect(
    (e) => {
      const fetchData = async () => {
        try {
          setIsShowLoader(true);
          let allHits = [];
          let page = 0;
          let response;

          do {
            const url = `https://${APPLICATION_ID}-dsn.algolia.net/1/indexes/diseases?page=${page}&hitsPerPage=1000`;
            response = await axios.get(url, {
              headers: {
                "X-Algolia-API-Key": API_KEY,
                "X-Algolia-Application-Id": APPLICATION_ID,
              },
            });

            allHits = [...allHits, ...response.data.hits];
            page++;
          } while (response.data.hits.length > 0);
          setHitsData(allHits);
          setDiagnosesModel({
            ...diagnosesModel,
            isOpenSidebar: true,
          });
          setIsShowLoader(false);
        } catch (error) {
          console.error(error);
          setIsShowLoader(false);
        }
      };

      fetchData();
    },
    [checkedSyndromes]
  );

  useEffect(() => {
    if (selectedCheckBoxRef.current && customHitsRef.current) {
      const selectedCheckBoxHeight = selectedCheckBoxRef.current.offsetHeight;
      const headerHeight = 68;
      const searchBoxHeight = 46;

      const sumHeight = selectedCheckBoxHeight + headerHeight + searchBoxHeight;

      customHitsRef.current.style.maxHeight = `calc(100vh - ${sumHeight}px)`;
    }
  });

  const selectedSyndromes = useMemo(() => {
    return Object.values(checkedSyndromes)
      .filter((item) => item.checked)
      .map((item) => item.name);
  }, [checkedSyndromes]);

  useEffect(() => {
    const { commonDiagnoses, allPossibleDiagnoses } =
      hitsData &&
      findMatchedDiagnosesBasedOnSyndrome(hitsData, checkedSyndromes);
    setAllDiagnoses(allPossibleDiagnoses);
    setCommonDiagnoses(commonDiagnoses);
  }, [hitsData, checkedSyndromes]);

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const setCheckedItem = useCallback(
    (hit) => {
      setCheckedItems((prevCheckedItems) => ({
        ...prevCheckedItems,
        [hit.objectID]: {
          ...prevCheckedItems[hit.objectID],
          checked: !prevCheckedItems[hit.objectID]?.checked,
          name: hit.name,
        },
      }));
    },
    [setCheckedItems]
  );

  const showDiagnoses = (hit) => {
    return (
      <Checkbox
        isSyndrome={false}
        key={hit.objectID}
        hit={hit}
        checked={checkedItems[hit.objectID]?.checked || false}
        onCheckboxChange={() => setCheckedItem(hit)}
      />
    );
  };

  const renderDiagnoses = () => {
    const filteredAllDiagnoses = allDiagnoses.filter((diagnoses) =>
      diagnoses.name.toLowerCase().includes(query.toLowerCase())
    );
    const filteredCommonDiagnoses = commonDiagnoses.filter((diagnoses) =>
      diagnoses.name.toLowerCase().includes(query.toLowerCase())
    );

    return (
      <div>
        {filteredCommonDiagnoses.length > 0 && (
          <div className="mb-4">
            <h3 className="block font-medium mb-2 ml-4 text-xl font-poppinsMedium text-[#03254c] tex-center min-w-[200px]">
              Common Diagnosis
            </h3>
            {filteredCommonDiagnoses.map((diagnoses) =>
              showDiagnoses(diagnoses)
            )}
          </div>
        )}
        {filteredAllDiagnoses.length > 0 && (
          <div className="mb-4">
            <h3 className="block font-medium mb-2 ml-4 text-xl font-poppinsMedium text-[#03254c] tex-center min-w-[200px]">
              All Possible Diagnosis
            </h3>
            {filteredAllDiagnoses.map((diagnoses) => showDiagnoses(diagnoses))}
          </div>
        )}
        {filteredCommonDiagnoses.length === 0 &&
          filteredAllDiagnoses.length === 0 && (
            <p className="mx-2 flex justify-center items-center">
              No Diagnosis found
            </p>
          )}
      </div>
    );
  };

  const selectedCheckBox = Object.values(checkedItems).filter(
    (item) => item.checked
  );

  const uncheckName = (data, nameToUncheck) => {
    const updated = { ...data };

    Object.values(updated).forEach((item) => {
      if (item.name === nameToUncheck) {
        item.checked = false;
      }
    });

    return updated;
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsDiagnosesModelOpen(true);

    if (firstClicked.isSyndromeSelected) {
      setFirstClicked({
        ...firstClicked,
        isDiagnosesSelected: false,
      });
    } else {
      setFirstClicked({
        ...firstClicked,
        isDiagnosesSelected: true,
      });
    }
    const algoliaClient = client(
      "5OSPL9PYUS",
      "d62ae06c780fca089f35d07a53760ef4"
    );
    let index = algoliaClient.initIndex("diseases");
    index.setSettings({
      searchableAttributes: ["name"],
    });
  };

  const handleDoneAndOpenSyndromes = (e) => {
    // Close diagnoses modal
    handleButtonClickModelClose(e);

    // Open syndromes modal with slight delay to ensure smooth transition
    setTimeout(() => {
      setIsSyndromeModelOpen(true);
      setFirstClicked({
        ...firstClicked,
        isDiagnosesSelected: true,
        isSyndromeSelected: true,
      });
    }, 100);
  };

  const handleButtonClickModelClose = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent event bubbling

    // Handle both mouse and touch events
    if (e.type === "touchend") {
      e.preventDefault(); // Prevent any default touch behavior
    }
    setIsDiagnosesModelOpen(false);
    setShowMessage(true);
    setBodyPart(bodyPart);

    if (firstClicked.isDiagnosesSelected) {
      if (Object.entries(checkedItems).length === 0) {
        setFirstClicked({
          ...firstClicked,
          isDiagnosesSelected: false,
        });
      }
    }
    document.body.style.overflowY = "scroll";
  };

  const handleClick = (event) => {
    if (backdropRef.current && !backdropRef.current.contains(event.target)) {
      handleButtonClickModelClose(event);
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const draggableTabStyles = {
    position: "center",
    width: windowWidth <= 1024 ? (windowWidth <= 768 ? "80%" : "60%") : "40%",
    minWidth: "300px",
    backgroundColor: "white",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    borderRadius: "8px",
    // cursor: 'move',
    resize: "both",
    overflow: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    height: "80vh",
    "@media (max-width: 1024px)": {
      width: "90%", // Full width for tablets0%", // Increased width for tablets
    },
    "@media (max-width: 768px)": {
      width: "90%", // Even wider for smaller tablets/mobile
    }
  };

  return (
    <>
      <button
        type="button"
        className="px-4 py-3 font-poppinsMedium text-white border rounded bg-[#03254C]"
        onClick={handleButtonClick}
      >
        Search Diagnosis
      </button>

      {isDiagnosesModelOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-25 z-[999]">
          <Draggable handle=".drag-handle">
            <div style={draggableTabStyles}>
              <InstantSearch searchClient={searchClient} indexName="diseases">
                <Configure hitsPerPage={1000} />
                {firstClicked.isSyndromeSelected ? (
                  <div className="h-full">
                    <div className="flex flex-col h-full">
                      <div className="sticky top-0 bg-white z-10 border-b drag-handle">
                        <div className="flex items-center py-2">
                          <button
                            className="px-4" // Increased touch target area
                            onClick={handleButtonClickModelClose}
                            onTouchEnd={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleButtonClickModelClose(e);
                            }}
                            style={{ touchAction: "manipulation" }}
                          >
                            <svg
                              className="w-4 h-4" // Increased icon size for better touch target
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                              style={{ touchAction: "manipulation" }}
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                          </button>
                          <p className="font-poppinsMedium text-lg w-full text-center pr-6">
                            Diagnosis
                          </p>
                        </div>
                        <div className="px-2 pb-2">
                          <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                              <svg
                                className="w-4 h-4 text-gray-500"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                              </svg>
                            </div>
                            <input
                              type="search"
                              className="w-full p-2 pl-10 text-sm border rounded-lg"
                              value={query}
                              onChange={handleSearch}
                              placeholder="Search Diagnosis Here"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex-1 overflow-auto p-2">
                        {selectedCheckBox.length > 0 && (
                          <div className="flex flex-wrap gap-2 mb-2">
                            {selectedCheckBox.map((item) => (
                              <div
                                key={item.name}
                                className="flex items-center bg-[#e9e9e9] text-black px-2 py-1 rounded-3xl"
                              >
                                <span>{item.name}</span>
                                <button
                                  className="ml-2"
                                  onClick={() => {
                                    setCheckedItems((prev) =>
                                      uncheckName(prev, item.name)
                                    );
                                  }}
                                >
                                  <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                  </svg>
                                </button>
                              </div>
                            ))}
                          </div>
                        )}

                        {isShowLoader ? (
                          <div className="animate-pulse">
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                            <div className="h-12 mx-2 bg-gray-200 rounded w-[98%] mb-4"></div>
                          </div>
                        ) : (
                          renderDiagnoses()
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="h-full">
                    <div className="flex flex-col h-full">
                      <div className="sticky top-0 bg-white z-10 border-b drag-handle">
                        <div className="flex items-center py-2">
                          <button
                            className="px-2 " // Increased padding for better touch target
                            onClick={handleButtonClickModelClose}
                            onTouchEnd={(e) => {
                              e.preventDefault(); // Prevent ghost clicks
                              handleButtonClickModelClose(e);
                            }}
                          >
                            <svg
                              className="w-4 h-4" // Increased size for better touch target
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                              style={{ touchAction: "manipulation" }} // Improve touch handling
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                          </button>
                          <p className="font-poppinsMedium text-lg w-full text-center pr-6">
                            Diagnosis
                          </p>
                          {selectedCheckBox.length > 0 && (
                            <div className="flex flex-col items-center">
                              <button
                                className="px-6 py-2 mr-6 text-white bg-red-500 rounded hover:bg-red-600 transition duration-200 touch-manipulation"
                                onClick={handleDoneAndOpenSyndromes}
                                onTouchEnd={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleDoneAndOpenSyndromes(e);
                                }}
                                style={{ touchAction: "manipulation" }}
                              >
                                Done
                              </button>
                              {/* {showMessage && (
      <div className="mt-1 text-sm text-green-600 bg-green-100 border border-green-300 rounded p-1">
        Further clicks on syndromes will be processed.
      </div>
    )} */}
                            </div>
                          )}
                        </div>

                        <div className="px-2 pb-2">
                          <SearchBox
                            placeholder="Search Diagnosis Here"
                            classNames={{
                              root: "w-full",
                              input:
                                "w-full p-2 pl-10 text-sm border rounded-lg",
                              submit: "hidden",
                              reset: "hidden",
                            }}
                          />

                          {selectedCheckBox.length > 0 && (
                            <div className="mt-2 text-sm text-green-600 bg-green-100 border border-green-300 rounded p-1">
                              Please click Done to find out associated
                              syndromes.
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex-1 overflow-auto p-2">
                        {selectedCheckBox.length > 0 && (
                          <div className="flex flex-wrap gap-2 mb-2">
                            {selectedCheckBox.map((item) => (
                              <div
                                key={item.name}
                                className="flex items-center bg-[#e9e9e9] text-black px-2 py-1 rounded-3xl"
                              >
                                <span>{item.name}</span>
                                <button
                                  className="ml-2"
                                  onClick={() => {
                                    setCheckedItems((prev) =>
                                      uncheckName(prev, item.name)
                                    );
                                  }}
                                >
                                  <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                  </svg>
                                </button>
                              </div>
                            ))}
                          </div>
                        )}

                        <CustomHits
                          checkedItems={checkedItems}
                          setCheckedItems={setCheckedItems}
                          firstClicked={firstClicked}
                          checkedSyndromes={checkedSyndromes}
                          bodyPart={bodyPart}
                          setIsOpenModel={setDiagnosesModel}
                          isOpenModel={diagnosesModel}
                          hitsData={hitsData}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </InstantSearch>
            </div>
          </Draggable>
        </div>
      )}
    </>
  );
}

export default SidebarDiagnoses;
