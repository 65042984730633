import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import OtpInput from "react-otp-input";
import ScanOFeLogo from "../assets/ScanOFeMainLogo.png";
import heartIcon from "../assets/hearth-icon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import { useAppState } from "../Context";
import useMainState from "../Context/state/useMainState";

function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [selectedCountry, setSelectedCountry] = useState("IN");
  const [selectedCountryName, setSelectedCountryName] = useState("India");
  const [showCountryDropdown, setShowCountryDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const [isOtpSend, setIsOtpSend] = useState(false);
  const [otp, setOtp] = useState();
  const { setIsAuthenticated } = useAppState("auth");
  const { setProfileId } = useMainState("app");
  const [userId, setUserId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is already logged in
    const isActive = localStorage.getItem("isAuthenticated") === "true";

    if (isActive) {
      // Redirect to the welcome page if the user is already logged in
      navigate("/welcome");
    }
  }, [navigate]);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCountryDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const checkUserProfile = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/scanofe-users/${id}`
      );
      const profileData = response.data.data.attributes;
      const isProfileCreated = checkPropertiesNotNull(profileData);
      return isProfileCreated;
    } catch (error) {
      console.error(error, "Error while checking Profile data ");
      return false;
    }
  };

  const checkPropertiesNotNull = (data) => {
    for (const key in data) {
      const value = data[key];
      if (key !== "website" && (value === null || value === "null")) {
        return false;
      }
    }
    return true;
  };

  const generateOTP = async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/otp/generate`,
        payload
      );
      if (response.status === 200) {
        // Store the user ID but don't set authenticated yet
        setUserId(response.data.id);
        setProfileId(response.data.id);
        toast.success("Verification code sent successfully");
        setIsOtpSend(true);
      }
    } catch (error) {
      console.error(error, "Error while generating otp");
      toast.error("Failed to send verification code. Please try again.");
    }
  };

  const verifyOTP = async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/otp/verify`,
        payload
      );
      if (response.status === 200) {
        // Only set authenticated after successful OTP verification
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", "true");
        toast.success(response.data.success);
        setTimeout(async () => {
          const isProfileCreated = await checkUserProfile(response.data.id);
          if (isProfileCreated) {
            navigate("/welcome");
          } else {
            navigate("/profile");
          }
        }, 2000);
      }
    } catch (error) {
      console.error(error, "Error while verifying otp");
      toast.error("Invalid verification code. Please try again.");
    }
  };

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (isOtpSend) {
      if (!otp || otp.length !== 4) {
        toast.error("Please enter the 4-digit verification code");
        return;
      }

      const payload = {
        data: {
          mobile_number: phoneNumber,
          country_code: countryCode.replace('+', ''),
          otp: otp,
        },
      };

      verifyOTP(payload);
    } else {
      if (!phoneNumber) {
        toast.error("Please enter a valid phone number");
        return;
      }

      const payload = {
        data: {
          mobile_number: phoneNumber,
          country_code: countryCode.replace('+', ''),
        },
      };
      generateOTP(payload);
    }
  };

  // List of countries with their flags, dial codes
  const countries = [
    { name: "Afghanistan", code: "AF", dialCode: "+93" },
    { name: "Albania", code: "AL", dialCode: "+355" },
    { name: "Algeria", code: "DZ", dialCode: "+213" },
    { name: "American Samoa", code: "AS", dialCode: "+1684" },
    { name: "Andorra", code: "AD", dialCode: "+376" },
    { name: "Angola", code: "AO", dialCode: "+244" },
    { name: "Anguilla", code: "AI", dialCode: "+1264" },
    { name: "Antarctica", code: "AQ", dialCode: "+672" },
    { name: "Antigua and Barbuda", code: "AG", dialCode: "+1268" },
    { name: "Argentina", code: "AR", dialCode: "+54" },
    { name: "Armenia", code: "AM", dialCode: "+374" },
    { name: "Aruba", code: "AW", dialCode: "+297" },
    { name: "Australia", code: "AU", dialCode: "+61" },
    { name: "Austria", code: "AT", dialCode: "+43" },
    { name: "Azerbaijan", code: "AZ", dialCode: "+994" },
    { name: "Bahamas", code: "BS", dialCode: "+1242" },
    { name: "Bahrain", code: "BH", dialCode: "+973" },
    { name: "Bangladesh", code: "BD", dialCode: "+880" },
    { name: "Barbados", code: "BB", dialCode: "+1246" },
    { name: "Belarus", code: "BY", dialCode: "+375" },
    { name: "Belgium", code: "BE", dialCode: "+32" },
    { name: "Belize", code: "BZ", dialCode: "+501" },
    { name: "Benin", code: "BJ", dialCode: "+229" },
    { name: "Bermuda", code: "BM", dialCode: "+1441" },
    { name: "Bhutan", code: "BT", dialCode: "+975" },
    { name: "Bolivia", code: "BO", dialCode: "+591" },
    { name: "Bosnia and Herzegovina", code: "BA", dialCode: "+387" },
    { name: "Botswana", code: "BW", dialCode: "+267" },
    { name: "Bouvet Island", code: "BV", dialCode: "+47" },
    { name: "Brazil", code: "BR", dialCode: "+55" },
    { name: "British Indian Ocean Territory", code: "IO", dialCode: "+246" },
    { name: "Brunei Darussalam", code: "BN", dialCode: "+673" },
    { name: "Bulgaria", code: "BG", dialCode: "+359" },
    { name: "Burkina Faso", code: "BF", dialCode: "+226" },
    { name: "Burundi", code: "BI", dialCode: "+257" },
    { name: "Cambodia", code: "KH", dialCode: "+855" },
    { name: "Cameroon", code: "CM", dialCode: "+237" },
    { name: "Canada", code: "CA", dialCode: "+1" },
    { name: "Cape Verde", code: "CV", dialCode: "+238" },
    { name: "Cayman Islands", code: "KY", dialCode: "+1345" },
    { name: "Central African Republic", code: "CF", dialCode: "+236" },
    { name: "Chad", code: "TD", dialCode: "+235" },
    { name: "Chile", code: "CL", dialCode: "+56" },
    { name: "China", code: "CN", dialCode: "+86" },
    { name: "Christmas Island", code: "CX", dialCode: "+61" },
    { name: "Cocos (Keeling) Islands", code: "CC", dialCode: "+61" },
    { name: "Colombia", code: "CO", dialCode: "+57" },
    { name: "Comoros", code: "KM", dialCode: "+269" },
    { name: "Congo", code: "CG", dialCode: "+242" },
    { name: "Congo, Democratic Republic of the", code: "CD", dialCode: "+243" },
    { name: "Cook Islands", code: "CK", dialCode: "+682" },
    { name: "Costa Rica", code: "CR", dialCode: "+506" },
    { name: "Cote D'Ivoire", code: "CI", dialCode: "+225" },
    { name: "Croatia", code: "HR", dialCode: "+385" },
    { name: "Cuba", code: "CU", dialCode: "+53" },
    { name: "Cyprus", code: "CY", dialCode: "+357" },
    { name: "Czech Republic", code: "CZ", dialCode: "+420" },
    { name: "Denmark", code: "DK", dialCode: "+45" },
    { name: "Djibouti", code: "DJ", dialCode: "+253" },
    { name: "Dominica", code: "DM", dialCode: "+1767" },
    { name: "Dominican Republic", code: "DO", dialCode: "+1849" },
    { name: "Ecuador", code: "EC", dialCode: "+593" },
    { name: "Egypt", code: "EG", dialCode: "+20" },
    { name: "El Salvador", code: "SV", dialCode: "+503" },
    { name: "Equatorial Guinea", code: "GQ", dialCode: "+240" },
    { name: "Eritrea", code: "ER", dialCode: "+291" },
    { name: "Estonia", code: "EE", dialCode: "+372" },
    { name: "Ethiopia", code: "ET", dialCode: "+251" },
    { name: "Falkland Islands (Malvinas)", code: "FK", dialCode: "+500" },
    { name: "Faroe Islands", code: "FO", dialCode: "+298" },
    { name: "Fiji", code: "FJ", dialCode: "+679" },
    { name: "Finland", code: "FI", dialCode: "+358" },
    { name: "France", code: "FR", dialCode: "+33" },
    { name: "French Guiana", code: "GF", dialCode: "+594" },
    { name: "French Polynesia", code: "PF", dialCode: "+689" },
    { name: "French Southern Territories", code: "TF", dialCode: "+262" },
    { name: "Gabon", code: "GA", dialCode: "+241" },
    { name: "Gambia", code: "GM", dialCode: "+220" },
    { name: "Georgia", code: "GE", dialCode: "+995" },
    { name: "Germany", code: "DE", dialCode: "+49" },
    { name: "Ghana", code: "GH", dialCode: "+233" },
    { name: "Gibraltar", code: "GI", dialCode: "+350" },
    { name: "Greece", code: "GR", dialCode: "+30" },
    { name: "Greenland", code: "GL", dialCode: "+299" },
    { name: "Grenada", code: "GD", dialCode: "+1473" },
    { name: "Guadeloupe", code: "GP", dialCode: "+590" },
    { name: "Guam", code: "GU", dialCode: "+1671" },
    { name: "Guatemala", code: "GT", dialCode: "+502" },
    { name: "Guernsey", code: "GG", dialCode: "+44" },
    { name: "Guinea", code: "GN", dialCode: "+224" },
    { name: "Guinea-Bissau", code: "GW", dialCode: "+245" },
    { name: "Guyana", code: "GY", dialCode: "+592" },
    { name: "Haiti", code: "HT", dialCode: "+509" },
    { name: "Heard Island and Mcdonald Islands", code: "HM", dialCode: "+672" },
    { name: "Holy See (Vatican City State)", code: "VA", dialCode: "+379" },
    { name: "Honduras", code: "HN", dialCode: "+504" },
    { name: "Hong Kong", code: "HK", dialCode: "+852" },
    { name: "Hungary", code: "HU", dialCode: "+36" },
    { name: "Iceland", code: "IS", dialCode: "+354" },
    { name: "India", code: "IN", dialCode: "+91" },
    { name: "Indonesia", code: "ID", dialCode: "+62" },
    { name: "Iran", code: "IR", dialCode: "+98" },
    { name: "Iraq", code: "IQ", dialCode: "+964" },
    { name: "Ireland", code: "IE", dialCode: "+353" },
    { name: "Isle of Man", code: "IM", dialCode: "+44" },
    { name: "Israel", code: "IL", dialCode: "+972" },
    { name: "Italy", code: "IT", dialCode: "+39" },
    { name: "Jamaica", code: "JM", dialCode: "+1876" },
    { name: "Japan", code: "JP", dialCode: "+81" },
    { name: "Jersey", code: "JE", dialCode: "+44" },
    { name: "Jordan", code: "JO", dialCode: "+962" },
    { name: "Kazakhstan", code: "KZ", dialCode: "+7" },
    { name: "Kenya", code: "KE", dialCode: "+254" },
    { name: "Kiribati", code: "KI", dialCode: "+686" },
    { name: "Korea, Democratic People's Republic of", code: "KP", dialCode: "+850" },
    { name: "Korea, Republic of", code: "KR", dialCode: "+82" },
    { name: "Kosovo", code: "XK", dialCode: "+383" },
    { name: "Kuwait", code: "KW", dialCode: "+965" },
    { name: "Kyrgyzstan", code: "KG", dialCode: "+996" },
    { name: "Lao People's Democratic Republic", code: "LA", dialCode: "+856" },
    { name: "Latvia", code: "LV", dialCode: "+371" },
    { name: "Lebanon", code: "LB", dialCode: "+961" },
    { name: "Lesotho", code: "LS", dialCode: "+266" },
    { name: "Liberia", code: "LR", dialCode: "+231" },
    { name: "Libyan Arab Jamahiriya", code: "LY", dialCode: "+218" },
    { name: "Liechtenstein", code: "LI", dialCode: "+423" },
    { name: "Lithuania", code: "LT", dialCode: "+370" },
    { name: "Luxembourg", code: "LU", dialCode: "+352" },
    { name: "Macao", code: "MO", dialCode: "+853" },
    { name: "Macedonia", code: "MK", dialCode: "+389" },
    { name: "Madagascar", code: "MG", dialCode: "+261" },
    { name: "Malawi", code: "MW", dialCode: "+265" },
    { name: "Malaysia", code: "MY", dialCode: "+60" },
    { name: "Maldives", code: "MV", dialCode: "+960" },
    { name: "Mali", code: "ML", dialCode: "+223" },
    { name: "Malta", code: "MT", dialCode: "+356" },
    { name: "Marshall Islands", code: "MH", dialCode: "+692" },
    { name: "Martinique", code: "MQ", dialCode: "+596" },
    { name: "Mauritania", code: "MR", dialCode: "+222" },
    { name: "Mauritius", code: "MU", dialCode: "+230" },
    { name: "Mayotte", code: "YT", dialCode: "+262" },
    { name: "Mexico", code: "MX", dialCode: "+52" },
    { name: "Micronesia, Federated States of", code: "FM", dialCode: "+691" },
    { name: "Moldova, Republic of", code: "MD", dialCode: "+373" },
    { name: "Monaco", code: "MC", dialCode: "+377" },
    { name: "Mongolia", code: "MN", dialCode: "+976" },
    { name: "Montenegro", code: "ME", dialCode: "+382" },
    { name: "Montserrat", code: "MS", dialCode: "+1664" },
    { name: "Morocco", code: "MA", dialCode: "+212" },
    { name: "Mozambique", code: "MZ", dialCode: "+258" },
    { name: "Myanmar", code: "MM", dialCode: "+95" },
    { name: "Namibia", code: "NA", dialCode: "+264" },
    { name: "Nauru", code: "NR", dialCode: "+674" },
    { name: "Nepal", code: "NP", dialCode: "+977" },
    { name: "Netherlands", code: "NL", dialCode: "+31" },
    { name: "Netherlands Antilles", code: "AN", dialCode: "+599" },
    { name: "New Caledonia", code: "NC", dialCode: "+687" },
    { name: "New Zealand", code: "NZ", dialCode: "+64" },
    { name: "Nicaragua", code: "NI", dialCode: "+505" },
    { name: "Niger", code: "NE", dialCode: "+227" },
    { name: "Nigeria", code: "NG", dialCode: "+234" },
    { name: "Niue", code: "NU", dialCode: "+683" },
    { name: "Norfolk Island", code: "NF", dialCode: "+672" },
    { name: "Northern Mariana Islands", code: "MP", dialCode: "+1670" },
    { name: "Norway", code: "NO", dialCode: "+47" },
    { name: "Oman", code: "OM", dialCode: "+968" },
    { name: "Pakistan", code: "PK", dialCode: "+92" },
    { name: "Palau", code: "PW", dialCode: "+680" },
    { name: "Palestinian Territory, Occupied", code: "PS", dialCode: "+970" },
    { name: "Panama", code: "PA", dialCode: "+507" },
    { name: "Papua New Guinea", code: "PG", dialCode: "+675" },
    { name: "Paraguay", code: "PY", dialCode: "+595" },
    { name: "Peru", code: "PE", dialCode: "+51" },
    { name: "Philippines", code: "PH", dialCode: "+63" },
    { name: "Pitcairn", code: "PN", dialCode: "+64" },
    { name: "Poland", code: "PL", dialCode: "+48" },
    { name: "Portugal", code: "PT", dialCode: "+351" },
    { name: "Puerto Rico", code: "PR", dialCode: "+1939" },
    { name: "Qatar", code: "QA", dialCode: "+974" },
    { name: "Reunion", code: "RE", dialCode: "+262" },
    { name: "Romania", code: "RO", dialCode: "+40" },
    { name: "Russian Federation", code: "RU", dialCode: "+7" },
    { name: "Rwanda", code: "RW", dialCode: "+250" },
    { name: "Saint Barthelemy", code: "BL", dialCode: "+590" },
    { name: "Saint Helena", code: "SH", dialCode: "+290" },
    { name: "Saint Kitts and Nevis", code: "KN", dialCode: "+1869" },
    { name: "Saint Lucia", code: "LC", dialCode: "+1758" },
    { name: "Saint Martin", code: "MF", dialCode: "+590" },
    { name: "Saint Pierre and Miquelon", code: "PM", dialCode: "+508" },
    { name: "Saint Vincent and the Grenadines", code: "VC", dialCode: "+1784" },
    { name: "Samoa", code: "WS", dialCode: "+685" },
    { name: "San Marino", code: "SM", dialCode: "+378" },
    { name: "Sao Tome and Principe", code: "ST", dialCode: "+239" },
    { name: "Saudi Arabia", code: "SA", dialCode: "+966" },
    { name: "Senegal", code: "SN", dialCode: "+221" },
    { name: "Serbia", code: "RS", dialCode: "+381" },
    { name: "Seychelles", code: "SC", dialCode: "+248" },
    { name: "Sierra Leone", code: "SL", dialCode: "+232" },
    { name: "Singapore", code: "SG", dialCode: "+65" },
    { name: "Slovakia", code: "SK", dialCode: "+421" },
    { name: "Slovenia", code: "SI", dialCode: "+386" },
    { name: "Solomon Islands", code: "SB", dialCode: "+677" },
    { name: "Somalia", code: "SO", dialCode: "+252" },
    { name: "South Africa", code: "ZA", dialCode: "+27" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS", dialCode: "+500" },
    { name: "South Sudan", code: "SS", dialCode: "+211" },
    { name: "Spain", code: "ES", dialCode: "+34" },
    { name: "Sri Lanka", code: "LK", dialCode: "+94" },
    { name: "Sudan", code: "SD", dialCode: "+249" },
    { name: "Suriname", code: "SR", dialCode: "+597" },
    { name: "Svalbard and Jan Mayen", code: "SJ", dialCode: "+47" },
    { name: "Swaziland", code: "SZ", dialCode: "+268" },
    { name: "Sweden", code: "SE", dialCode: "+46" },
    { name: "Switzerland", code: "CH", dialCode: "+41" },
    { name: "Syrian Arab Republic", code: "SY", dialCode: "+963" },
    { name: "Taiwan", code: "TW", dialCode: "+886" },
    { name: "Tajikistan", code: "TJ", dialCode: "+992" },
    { name: "Tanzania, United Republic of", code: "TZ", dialCode: "+255" },
    { name: "Thailand", code: "TH", dialCode: "+66" },
    { name: "Timor-Leste", code: "TL", dialCode: "+670" },
    { name: "Togo", code: "TG", dialCode: "+228" },
    { name: "Tokelau", code: "TK", dialCode: "+690" },
    { name: "Tonga", code: "TO", dialCode: "+676" },
    { name: "Trinidad and Tobago", code: "TT", dialCode: "+1868" },
    { name: "Tunisia", code: "TN", dialCode: "+216" },
    { name: "Turkey", code: "TR", dialCode: "+90" },
    { name: "Turkmenistan", code: "TM", dialCode: "+993" },
    { name: "Turks and Caicos Islands", code: "TC", dialCode: "+1649" },
    { name: "Tuvalu", code: "TV", dialCode: "+688" },
    { name: "Uganda", code: "UG", dialCode: "+256" },
    { name: "Ukraine", code: "UA", dialCode: "+380" },
    { name: "United Arab Emirates", code: "AE", dialCode: "+971" },
    { name: "United Kingdom", code: "GB", dialCode: "+44" },
    { name: "United States", code: "US", dialCode: "+1" },
    { name: "Uruguay", code: "UY", dialCode: "+598" },
    { name: "Uzbekistan", code: "UZ", dialCode: "+998" },
    { name: "Vanuatu", code: "VU", dialCode: "+678" },
    { name: "Venezuela", code: "VE", dialCode: "+58" },
    { name: "Vietnam", code: "VN", dialCode: "+84" },
    { name: "Virgin Islands, British", code: "VG", dialCode: "+1284" },
    { name: "Virgin Islands, U.S.", code: "VI", dialCode: "+1340" },
    { name: "Wallis and Futuna", code: "WF", dialCode: "+681" },
    { name: "Western Sahara", code: "EH", dialCode: "+212" },
    { name: "Yemen", code: "YE", dialCode: "+967" },
    { name: "Zambia", code: "ZM", dialCode: "+260" },
    { name: "Zimbabwe", code: "ZW", dialCode: "+263" }
  ];
  
  const selectCountry = (country) => {
    setSelectedCountry(country.code);
    setCountryCode(country.dialCode);
    setSelectedCountryName(country.name);
    setShowCountryDropdown(false);
  };

  return (
    <section className="h-screen w-full flex flex-col">
      <div className="h-[calc(100vh_-_41px)] relative overflow-y-auto">
        <div className="w-full max-w-[512px]  text-center absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%]">
          <div className="text-center pb-8">
            <a href="#" className="inline-block">
              <img className="w-72" src={ScanOFeLogo} alt="ScanOFe" />
            </a>
            <p className="text-[#03254c] font-poppinsMedium pt-1">
              To Make Fetal Diagnosis Easily Reachable
            </p>
          </div>

          <div className="shadow-custom-2 rounded-md px-5 py-8">
            <h1 className="text-[28px] font-poppinsMedium text-[#03254c] pb-4">
              Access to ScanOFe
            </h1>
            <form className="" onSubmit={handleFormSubmit}>
              <div className="custom-phone-input-wrapper" ref={dropdownRef}>
                <div 
                  className="custom-phone-prefix"
                  onClick={() => setShowCountryDropdown(!showCountryDropdown)}
                >
                  <img 
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedCountry}.svg`} 
                    alt={selectedCountryName} 
                    className="country-flag"
                  />
                  <span className="country-code">{countryCode}</span>
                  <svg className="dropdown-arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5 5L9 1" stroke="#666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                <input 
                  type="tel"
                  className="custom-phone-field"
                  placeholder="Enter your phone number"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                />
                
                {/* Country dropdown */}
                {showCountryDropdown && (
                  <div className="country-dropdown">
                    {countries.map((country) => (
                      <div 
                        key={country.code} 
                        className={`country-item ${country.code === selectedCountry ? 'selected' : ''}`}
                        onClick={() => selectCountry(country)}
                      >
                        <div className="country-item-left">
                          <img 
                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.code}.svg`} 
                            alt={country.name} 
                            className="country-flag-small"
                          />
                          <span className="country-name">{country.name}</span>
                        </div>
                        <span className="country-dial-code">{country.dialCode}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {isOtpSend && (
                <>
                  <p className="mt-4 text-sm text-gray-600">
                    Enter the 4-digit code sent to your phone
                  </p>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderInput={(props) => <input {...props} />}
                    containerStyle={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "3rem",
                      paddingTop: "1.25rem"
                    }}
                    inputStyle={{
                      height: "3rem",
                      width: "3rem",
                      border: "1px solid gainsboro",
                      borderRadius: "5px",
                    }}
                    shouldAutoFocus
                  />
                </>
              )}
                <button
                  type="submit"
                className="px-4 py-3 text-white border rounded bg-[#03254C] inline-block mt-6"
                >
                {isOtpSend ? "Verify Code" : "Send Verification Code"}
                </button>
            </form>
          </div>
        </div>
      </div>
      
      <ToastContainer />
      <div className="py-2.5 px-4 border-t border-[#dedede] absolute bottom-0 left-1/2 transform -translate-x-[50%] w-full bg-[#f0f0f0]">
        <p className="text-sm font-poppinsMedium flex items-center justify-center"> Developed with <img className="mx-1 h-4 w-4" src={heartIcon} alt="" /> by <a className="inline-block text-sm font-poppinsMedium ml-1" href="https://www.thirdrocktechkno.com" target="_blank"> Thirdrocktechkno</a></p>
      </div>
    </section>
  );
}

export default Login;
