import React from 'react';
import Tooltip from '../components/Tooltip';
import { Label } from '@mui/icons-material';
import STSCalculator from '../components/STSCalculator';
import RiskCalculator from '../components/RiskCalculator';

const TabletUIFetalBiometry = ({ 
    tableData, 
    handleInputChange, 
    handleChartOpen,
    getInputClassName,
    dueDate,
    convertWeeksDaysToDecimal,
    dateOfBirth,  // Add this
    setSTSLR,     // Add this
    setTableData  // Add this
}) => {

  const handleLRChange = (value) => {
    if (typeof setSTSLR === 'function') {
      setSTSLR(value);
      setTableData((prev) => ({
        ...prev,
        STS_LR: value,
      }));
    }
  };

  const MeasurementField = ({ label, name, tooltip, unit, reference, value, isDisabled = false }) => (
    <div className="flex items-center mb-4 gap-x-2">
      <div className="w-32">
        <Tooltip text={tooltip}>
          <label className="text-lg font-poppinsMedium text-[#03254c]">
            {label} <span className="text-xs text-[#03254cb7]">({unit})</span>
          </label>
          <p className="text-xs text-gray-600 font-sans">
            {localStorage.getItem(`${name}_reference`) || reference}
          </p>
        </Tooltip>
      </div>
      <input
        value={value !== 0 ? value : ""}
        onChange={(e) => handleInputChange(name, e.target.value)}
        type="number"
        disabled={isDisabled}
        className={getInputClassName(name, value, dueDate.weeks)}
        placeholder="0"
      />
      <Tooltip text={`Chart of ${label}`}>
        <svg
          onClick={() => handleChartOpen(name, value)}
          className="cursor-pointer text-white ml-1"
          fill="#03254c"
          width="30px"
          height="30px"
          viewBox="-0.063 0 0.594 0.594"
        >
          <path d="M0.424 0.476H0.045A0.018 0.018 0 0 1 0.027 0.459V0.099a0.018 0.018 0 1 1 0.035 0v0.344h0.361a0.018 0.018 0 0 1 0 0.035zM0.098 0.408V0.215A0.015 0.015 0 0 1 0.112 0.2h0.025a0.015 0.015 0 0 1 0.015 0.015v0.191zm0.088 0V0.11A0.015 0.015 0 0 1 0.2 0.095h0.025a0.015 0.015 0 0 1 0.015 0.015v0.297zm0.088 0V0.215A0.015 0.015 0 0 1 0.288 0.2h0.025a0.015 0.015 0 0 1 0.015 0.015v0.191zm0.088 0V0.321A0.015 0.015 0 0 1 0.375 0.306h0.025a0.015 0.015 0 0 1 0.015 0.015v0.086z" />
        </svg>
      </Tooltip>
    </div>
  );

  return (
    <div className="flex flex-col gap-8">
      {/* Fetal Biometry Section */}
      <div>
        <h3 className="text-xl font-poppinsMedium text-[#03254c] text-center mb-4">
          Fetal Biometry
        </h3>
        <div className="grid grid-cols-2 gap-4">
          {[
            { label: 'BPD', name: 'BPD', tooltip: 'Biparietal Diameter', unit: 'mm', reference: 'P_Acharya' },
            { label: 'HC', name: 'HC', tooltip: 'Head Circumference', unit: 'mm', reference: 'P_Acharya' },
            { label: 'AC', name: 'AC', tooltip: 'Abdominal Circumference', unit: 'mm', reference: 'P_Acharya' },
            { label: 'FL', name: 'FL', tooltip: 'Femur Length', unit: 'mm', reference: 'P_Acharya' },
            { label: 'EFW', name: 'EFW', tooltip: 'Estimated Fetal Weight', unit: 'mm', reference: 'P_Acharya' },
            { label: 'TCD', name: 'TCD', tooltip: 'Transverse Cerebellar Diameter', unit: 'mm', reference: 'Hill_LM' },
            { label: 'SLVP', name: 'SLVP', tooltip: 'Single Largest Vertical Pocket', unit: 'mm', reference: 'Magann_EF' },
            { label: 'AFI', name: 'AFI', tooltip: 'Amniotic Fluid Index', unit: 'mm', reference: 'Magann_EF' }
           
          ].map(item => (
            <MeasurementField 
              key={item.name}
              {...item}
              value={tableData[item.name]}
              
            />
          ))}
        </div>
      </div>

      {/* Doppler Section */}
      <div>
        <h3 className="text-xl font-poppinsMedium text-[#03254c] text-center mb-4">
          Doppler
        </h3>
        <div className="grid grid-cols-2 gap-4">
          {[
            { label: 'UA', name: 'UA', tooltip: 'Umbilical Artery', unit: 'mm', reference: 'Baschat' },
            { label: 'MCA', name: 'MCA', tooltip: 'Middle Cerebral Artery', unit: 'mm', reference: 'Baschat' },
            { label: 'CPR', name: 'CPR', tooltip: 'Cerebroplacental Ratio', unit: 'mm', reference: 'Baschat', isDisabled: true },
            { label: 'DV', name: 'DV', tooltip: 'Ductus Venosus', unit: 'mm', reference: 'Kessler' },
            { label: 'L.UtA', name: 'L.UtA', tooltip: 'Left Uterine', unit: 'mm', reference: 'O_Gemex' },
            { label: 'R.UtA', name: 'R.UtA', tooltip: 'Right Uterine', unit: 'mm', reference: 'O_Gemex' },
            { label:'M.UtA', name: 'M.UtA', tooltip: 'Mean Uterine', unit: 'mm', reference: 'O_Gemex' },
          ].map(item => (
            <MeasurementField 
              key={item.name}
              {...item}
              value={tableData[item.name]}
              isDisabled={item.isDisabled}
            />
          ))}
        </div>
      </div>

      <div>
      <h3 className="text-xl font-poppinsMedium text-[#03254c] text-center mb-4">
        FTS
      </h3>
      <div className="grid grid-cols-2 gap-4">
        {[
          { label: 'NT', name: 'NT', tooltip: 'Nuchal Translucency', unit: 'mm', reference: 'E_A_Junior' },
          { label: 'NB', name: 'NB', tooltip: 'Nasal Bone Length', unit: 'mm', reference: 'RB_Prakash' },
          { label: 'IT', name: 'IT', tooltip: 'Intracranial Translucency', unit: 'mm', reference: 'Sung_Hee_Yang' },
          { label: 'CRL', name: 'CRL', tooltip: 'Crown Rump Length', unit: 'mm', reference: 'L_J_SALOMON' }
        ].map(item => (
          <MeasurementField 
            key={item.name}
            {...item}
            value={tableData[item.name]}
          />
        ))}
      </div>
    </div>

    {/* STS Section */}
    <div className="mt-8">
      
        <h3 className="text-xl font-poppinsMedium text-[#03254c] text-center mb-6">
          2nd Trimester T21 Risk
        </h3>
     
      <div className="px-4">
        <div className="flex flex-col items-center gap-6">
          {/* LR Calculator */}
          <div className="w-full flex items-center justify-center gap-x-4">
            <STSCalculator
              onLRChange={handleLRChange}
              dateOfBirth={dateOfBirth}
              className="w-full"
            />
          </div>

          {/* Risk Calculator */}
          <div className="w-full">
            <RiskCalculator />
          </div>

          
        </div>
      </div>
    </div>
      
    </div>
  );
};

export default TabletUIFetalBiometry;