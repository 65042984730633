export const Fetal_Biometry_BD = {
  P_Acharya : {
  weeks: [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,],
  C_5th: [16.9, 20.2, 23.5, 26.6, 30, 33.2, 36.6, 39.9, 43.2, 46.6, 49.9, 53, 56,59.1, 61.9, 64.7, 67.5, 70.1, 72.3, 74.8, 76.8, 78.6, 80.2, 81.6, 82.8, 84,85.1, 86.2, 87.2, 88.5, 89.2,],
  C_50th: [19.3, 22.8, 26.1, 29.4, 32.9, 36.3, 39.7, 43.1, 46.4, 49.8, 53.1, 56.3,59.3, 62.5, 65.4, 68.4, 71.3, 73.9, 76.2, 78.8, 80.9, 82.7, 84.3, 85.8,87.2, 88.5, 89.7, 90.8, 91.9, 93.1, 93.9,],
  C_95th: [21.7, 25.4, 28.8, 32.1, 35.8, 39.3, 42.8, 46.2, 49.6, 53, 56.3, 59.6, 62.6,65.9, 69, 72.1, 75.1, 77.8, 80.2, 82.8, 84.9, 86.7, 88.4, 90, 91.5, 93,94.2, 95.5, 96.5, 97.8, 98.6,],
},
WHO: {
  weeks: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,33, 34, 35, 36, 37, 38, 39, 40,],
  C_5th: [24, 27, 30, 33, 36, 39, 42, 45, 48, 52, 55, 58, 60, 63, 66, 68, 71, 73, 75,77, 79, 80, 82, 84, 85, 87, 88,],
  C_50th: [27, 30, 33, 36, 40, 43, 47, 50, 53, 57, 60, 63, 66, 69, 72, 74, 77, 79, 81, 83, 85, 87, 89, 90, 92, 94, 96,],
  C_95th: [30, 33, 37, 40, 44, 47, 51, 54, 58, 61, 65, 68, 71, 74, 77, 80, 82, 85, 87,89, 91, 93, 95, 96, 98, 100, 101,],
},
Papageorghious: {
  weeks: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
  C_5th: [26.7, 29.6, 32.5, 35.5, 38.0, 41.6, 44.1, 47.8, 51.0, 54.0, 57.0, 60.0, 63.0, 65.9, 67.9, 71.3, 73.1, 76.3, 78.5, 80.6, 82.4, 84.1, 85.5, 86.7, 87.8, 88.2, 87.5],
  C_50th: [29.6, 32.6, 35.7, 38.8, 42.0, 45.2, 48.4, 51.7, 55.0, 58.2, 61.4, 64.5, 67.6, 70.6, 73.5, 76.3, 78.9, 81.4, 83.8, 85.9, 87.9, 89.7, 91.2, 92.5, 93.6, 94.4, 94.9],
  C_95th: [32.5, 35.6, 38.8, 42.1, 45.4, 48.8, 52.2, 55.6, 59.0, 62.4, 65.7, 69.0, 72.2, 75.3, 78.3, 81.2, 84.0, 86.6, 89.0, 91.3, 93.4, 95.2, 96.9, 98.4, 99.6, 100.6, 101.3]
},
Snjiders: {
  weeks: [ 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,],
  C_5th: [ 28, 31, 34, 36, 39, 42, 45, 48, 51, 54, 57, 60, 63, 66, 69, 72, 74, 77, 79, 81, 83, 85, 86, 87, 88, 89,],
  C_50th: [ 31, 34, 37, 40, 43, 46, 49, 52, 56, 59, 62, 66, 69, 72, 75, 78, 81, 83, 86, 88, 90, 92, 94, 95, 96, 97,],
  C_95th: [ 34, 37, 40, 43, 47, 50, 54, 57, 61, 64, 68, 71, 75, 78, 81, 85, 88, 90, 93, 96, 98, 100, 102, 103, 104, 105, ]
}};

export const DV = {
  Kessler: {
    weeks: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,],
  C_5th: [ 0.32, 0.32, 0.32, 0.32, 0.32, 0.31, 0.31, 0.31, 0.3, 0.29, 0.28, 0.28, 0.27, 0.26, 0.25, 0.24, 0.23, 0.22, 0.21,],
  C_50th: [0.57, 0.57, 0.57, 0.57, 0.57, 0.57, 0.56, 0.56, 0.55, 0.54, 0.53, 0.53,0.52, 0.51, 0.5, 0.49, 0.48, 0.46, 0.45,],
  "C_97.5th": [0.88, 0.88, 0.88, 0.88, 0.88, 0.87, 0.87, 0.86, 0.86, 0.85, 0.84, 0.83,0.82, 0.81, 0.8, 0.79, 0.78, 0.77, 0.76,],
},
Parra_cordero: {
  weeks: [23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
  C_5th: [0.38, 0.37, 0.36, 0.35, 0.33, 0.31, 0.31, 0.30, 0.29, 0.28, 0.27, 0.26, 0.25,0.24, 0.24, 0.23, 0.22, 0.20,],
  C_50th: [0.68, 0.66, 0.64, 0.62, 0.60, 0.58, 0.57, 0.55, 0.53, 0.52, 0.50, 0.49, 0.47,0.45, 0.43, 0.43, 0.41, 0.40,],
  C_95th: [1.09, 1.06, 1.04, 1.01, 0.99, 0.96, 0.94, 0.91, 0.89, 0.87, 0.85, 0.82, 0.80,0.78, 0.76, 0.74, 0.72, 0.70,],
}};

export const Fetal_Biometry_AC = {
  P_Acharya: {
  weeks: [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,],
  C_3rd: [50.5, 62, 72.9, 83.8, 94.3, 105.2, 115.6, 126.7, 137, 148.7, 157.7, 168.4,178, 187.9, 197.4, 207.8, 217.7, 228.1, 236.5, 245.8, 255, 263, 271.9,281.3, 288.1, 296.1, 302.4, 309.6, 314.4, 319.4, 324.4,],
  C_50th: [59.1, 71.1, 82.7, 93.9, 105.5, 117.1, 128.2, 140, 150.6, 162.7, 172.8, 184,194.2, 204.9, 215, 226.3, 236.8, 247.9, 256.5, 266.8, 276.9, 285.1, 294,303.2, 310.2, 318.7, 325.1, 332.3, 337.3, 342.3, 347.5,],
  C_95th: [66.7, 79.1, 91.2, 102.7, 115.4, 127.6, 139.3, 151.6, 162.4, 174.8, 185.9,197.6, 208.4, 219.9, 230.4, 242.6, 253.4, 265.2, 274.1, 285.1, 296, 304.5, 313.3, 322.4, 329.6, 338.4, 345, 352.2, 357.3, 362.5, 367.8,],
},
WHO: {
  weeks: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,33, 34, 35, 36, 37, 38, 39, 40,],
  C_5th: [71, 81, 91, 102, 113, 124, 137, 147, 159, 170, 181, 191, 201, 211, 220, 229,238, 246, 254, 262, 270, 279, 287, 296, 306, 316, 327,],
  C_50th: [ 81, 92, 103, 114, 126, 138, 150, 162, 173, 185, 197, 208, 219, 230, 240,250, 260, 269, 279, 288, 298, 307, 317, 328, 338, 350, 363],
  C_95th: [ 92, 103, 115, 127, 139, 152, 164, 177, 189, 202, 214, 226, 238, 249, 260, 271, 282, 292, 303, 313, 324, 335, 346, 358, 371, 384, 399],

},
Papageorghious: {
  weeks: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
  C_5th: [75.3, 85.8, 96.3, 106.7, 117.2, 127.6, 138.0, 148.3, 158.6, 168.9, 179.0, 189.1, 199.1, 209.1, 218.8, 228.5, 238.0, 247.4, 256.5, 265.5, 274.3, 282.8, 291.0, 299.0, 306.7, 314.1, 321.1],
  C_50th: [80.6, 91.9, 103.2, 114.4, 125.6, 136.7, 147.7, 158.7, 169.6, 180.4, 191.2, 201.8, 212.4, 222.9, 233.3, 243.6, 253.8, 263.9, 273.9, 283.8, 293.6, 303.3, 312.8, 322.3, 331.6, 340.8, 349.8],
  C_95th: [87.4, 99.8, 112.1, 124.3, 136.4, 148.4, 160.3, 172.0, 183.7, 195.3, 206.8, 218.1, 229.5, 240.7, 251.9, 263.0, 274.1, 285.2, 296.3, 307.4, 318.5, 329.6, 340.9, 352.1, 363.5, 375.0, 386.7]
},
Chitty: {  
  weeks: [12,13,14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,41,42], 
  C_5th:  [48.0, 58.5, 68.8, 79.1, 89.3, 99.5, 109.5, 119.5, 129.4, 139.2, 148.9, 158.5, 167.9, 177.3, 186.6, 195.7, 204.7, 213.5, 222.3, 230.8, 239.3, 247.6, 255.7, 263.7, 271.5, 279.1, 286.5, 293.8, 300.9, 307.8, 314.5],
  C_50th: [55.8, 67.4, 78.5, 90.3, 101.6, 112.9, 124.1, 135.2, 146.2, 157.1, 168.0, 178.7, 189.3, 199.8, 210.2, 220.4, 230.4, 240.4, 250.4, 260.1, 269.7, 279.1, 288.4, 297.5, 306.4, 315.1, 323.7, 332.1, 340.4, 348.4, 356.2],
  C_95th:[63.6, 76.3, 88.9, 101.5, 113.9, 126.4, 138.7, 150.9, 163.1, 175.1, 187.1, 198.9, 210.6, 222.3, 233.8, 245.2, 256.4, 267.6, 278.6, 289.4, 300.1, 310.6, 321.0, 331.3, 341.3, 351.2, 360.9, 370.5, 379.8, 389.0, 398.0]
},
Snjiders: { 
  weeks: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39], 
  C_5th:  [75.3, 85.8, 96.3, 106.7, 117.2, 127.6, 138.0, 148.3, 158.6, 168.9, 179.0, 189.1, 199.1, 209.1, 218.8, 228.5, 238.0, 247.4, 256.5, 265.5, 274.3, 282.8, 291.0, 299.0, 306.7, 314.1, 321.1],
  C_50th: [80.6, 91.9, 103.2, 114.4, 125.6, 136.7, 147.7, 158.7, 169.6, 180.4, 191.2, 201.8, 212.4, 222.9, 233.3, 243.6, 253.8, 263.9, 273.9, 283.8, 293.6, 303.3, 312.8, 322.3, 331.6, 340.8, 349.8],
  C_95th:[87.4, 99.8, 112.1, 124.3, 136.4, 148.4, 160.3, 172.0, 183.7, 195.3, 206.8, 218.1, 229.5, 240.7, 251.9, 263.0, 274.1, 285.2, 296.3, 307.4, 318.5, 329.6, 340.9, 352.1, 363.5, 375.0, 386.7]
}
};

export const Cerebellar_Hypoplasia = {
  weeks: [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,34, 35, 36, 37, 38,],
  C_5th: [1.42, 1.46, 1.52, 1.59, 1.68, 1.77, 1.88, 1.99, 2.12, 2.25, 2.39, 2.53,2.67, 2.82, 2.98, 3.19, 3.28, 3.44, 3.59, 3.78, 3.88, 4.01, 4.14, 4.27, ],
  C_50th: [1.58, 1.65, 1.73, 1.82, 1.92, 2.04, 2.16, 2.3, 2.44, 2.5, 2.74, 2.91, 3.07, 3.24, 3.42, 3.59, 3.77, 3.95, 4.13, 4.31, 4.48, 4.65, 4.82, 4.99, ],
  C_95th: [ 1.74, 1.86, 1.96, 2.05, 2.17, 2.3, 2.45, 2.6, 2.76, 2.93, 3.1, 3.28, 3.47,3.66, 3.86, 4.06, 4.26, 4.47, 4.67, 4.88, 5.09, 5.3, 5.5, 5.71,],
};

export const Fetal_Biometry_EFW = {
  P_Acharya : {
  weeks: [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, ],
  C_5th: [50, 64, 80, 100, 123, 151, 185, 226, 269, 325, 383, 452, 526, 616, 709, 819,940, 1072, 1195, 1343, 1493, 1642, 1797, 1965, 2097, 2257, 2399, 2534, 2648, 2756, 2879, ],
  C_50th: [ 60, 77, 97, 121, 150, 185, 228, 279, 333, 401, 475, 562, 656, 768, 884, 1022, 1170, 1339, 1494, 1680, 1868, 2047, 2233, 2430, 2593, 2781, 2949, 3107, 3242, 3372, 3515,],
  C_95th: [ 71, 91, 114, 143, 179, 220, 272, 334, 398, 480, 570, 676, 790, 926, 1065, 1232, 1408, 1613, 1799, 2025, 2249, 2456, 2671, 2896, 3087, 3300, 3491, 3670, 3822, 3973, 4133, ],},
  WHO: {
    weeks : [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
    C_5th:  [73, 93, 117, 146, 181, 223, 271, 327, 392, 465, 548, 641, 743, 855, 977, 1108, 1247, 1394, 1548, 1708, 1872, 2038, 2205, 2372, 2536, 2696, 2849],
    C_50th: [90, 114, 144, 179, 222, 272, 330, 398, 476, 565, 665, 778, 902, 1039, 1189, 1350, 1523, 1707, 1901, 2103, 2312, 2527, 2745, 2966, 3186, 3403, 3617],
    C_95th: [109, 138, 174, 217, 268, 328, 399, 481, 575, 682, 803, 938, 1087, 1251, 1429, 1622, 1828, 2046, 2276, 2516, 2764, 3018, 3277, 3538, 3799, 4058, 4312]
    },  
  Yudkin  : { 
  weeks : [24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42],
  C_3rd: [510, 550, 600, 680, 770, 910, 1060, 1200, 1360, 1520, 1680, 1830, 1990, 2150, 2290, 2420, 2540, 2650, 2650],
  C_50th: [740, 790, 870, 980, 1120, 1300, 1490, 1700, 1920, 2140, 2360, 2560, 2780, 3000, 3200, 3280, 3440, 3490, 3490],
  C_97th: [970, 1030, 1140, 1280, 1480, 1690, 1930, 2200, 2490, 2760, 3040, 3270, 3570, 3800, 3990, 4150, 4260, 4320, 4320]
   },};

export const Fetal_Biometry_FL = {
  P_Acharya : {
    weeks: [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,],
  C_5th: [ 5.6, 8.8, 11.7, 14.8, 17.8, 20.6, 23.7, 26.7, 29.1, 31.9, 34.6, 37.1, 39.6,42.2, 44.6, 47, 49.4, 51.7, 53.7, 55.8, 57.8, 59.7, 61.4, 63, 64.4, 66,67.5, 68.5, 69.4, 70.1, 71.2,],
  C_50th: [7.8, 11.1, 14, 17.2, 20.2, 23.1, 26.2, 29.2, 31.8, 34.5, 37.3, 39.9, 42.5,45.1, 47.5, 49.9, 52.3, 54.8, 57, 59.2, 61.2, 63.1, 64.9, 66.6, 68.2, 69.8,71.3, 72.4, 73.4, 74.2, 75.2,],
  C_95th: [ 10.1, 13.4, 16.4, 19.6, 22.6, 25.5, 28.7, 31.8, 34.4, 37.2, 40, 42.7, 45.4, 48, 50.4, 52.8, 55.3, 57.9, 60.3, 62.6, 64.6, 66.6, 68.5, 70.3, 72.1, 73.5, 75.1, 76.2, 77.4, 78.2, 79.2,],
},
Chitty: { 
  weeks: [12,13,14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
C_5th: [4.4,7.5,10.6, 13.6, 16.5, 19.4, 22.3, 25.1, 27.9, 30.6, 33.2, 35.8, 38.3, 40.8, 43.1, 45.4, 47.6, 49.8, 51.8, 53.8, 55.7, 57.5, 59.2, 60.8, 62.3, 63.7, 64.9, 66.1, 67.2],
C_50th: [7.7, 10.9, 14.1, 17.2, 20.3, 23.3, 26.3, 29.2, 32.1, 34.9, 37.6, 40.3, 42.9, 45.5, 48.0, 50.4, 52.7, 55.0, 57.1, 59.2, 61.2, 63.1, 64.9, 66.6, 68.2, 69.7, 71.1, 72.4, 73.6],
C_95th: [11.1, 14.4, 17.6, 20.8, 24.0, 27.2, 30.2, 33.3, 36.3, 39.2, 42.0, 44.8, 47.6, 50.2, 52.8, 55.3, 57.8, 60.1, 62.4, 64.6, 66.7, 68.7, 70.6, 72.4, 74.1, 75.8, 77.3, 78.7, 79.9]
},
Papageorghious: { 
weeks: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
  C_5th: [10.3, 13.4, 16.4, 19.4, 22.3, 25.2, 28.0, 30.6, 33.3, 35.8, 38.3, 40.6, 42.9, 45.1, 47.3, 49.3, 51.3, 53.2, 55.0, 56.7, 58.3, 59.8, 61.3, 62.6, 63.9, 65.0, 66.1],
  C_50th: [13.1, 16.3, 19.5, 22.5, 25.5, 28.5, 31.3, 34.1, 36.7, 39.4, 41.9, 44.4, 46.7, 49.0, 51.3, 53.4, 55.5, 57.5, 59.4, 61.3, 63.1, 64.8, 66.4, 67.9, 69.4, 70.8, 72.1],
  C_95th: [15.6, 18.9, 22.1, 25.3, 28.3, 31.3, 34.2, 37.0, 39.8, 42.5, 45.1, 47.6, 50.1, 52.5, 54.8, 57.0, 59.2, 61.3, 63.4, 65.3, 67.2, 69.1, 70.9, 72.6, 74.3, 75.9, 77.4]
},
Snjiders: {
weeks : [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39],
C_5th: [14, 17, 19, 21, 24, 26, 29, 32, 24, 27, 39, 42, 44, 47, 49, 51, 53, 55, 57, 59, 61, 63, 64, 66, 67, 68],
C_50th: [17, 19, 22, 24, 27, 30, 32, 35, 38, 41, 43, 46, 48, 51, 53, 56, 58, 60, 62, 64, 66, 68, 69, 71, 72, 73],
C_95th: [19, 22, 25, 28, 30, 33, 36, 39, 42, 45, 47, 50, 53, 55, 58, 60, 63, 65, 67, 69, 71, 73, 74, 76, 77, 78]
 },
WHO: {
weeks: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
C_5th: [10, 13, 16, 19, 22, 26, 29, 32, 35, 37, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 67, 68, 68],
C_50th: [13, 16, 19, 22, 26, 29, 32, 35, 38, 41, 43, 46, 48, 50, 52, 54, 56, 59, 61, 63, 65, 67, 69, 70, 72, 73, 73],
C_95th: [16, 19, 22, 26, 29, 32, 35, 38, 41, 44, 47, 49, 51, 54, 56, 58, 60, 63, 65, 67, 69, 71, 73, 75, 77, 78, 78]
 },
};

export const Fetal_Biometry_HC = {
  P_Acharya: {
  weeks: [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,],
  C_5th: [61.2, 75.1, 88.9, 102.8, 115.8, 128.8, 141.6, 153.8, 166.4, 178.3, 189.8,201.2, 211.4, 224, 233.8, 243.5, 253.3, 261.9, 269.5, 278.7, 285.6, 292.8,298.5, 304, 306.5, 309.6, 312.6, 314.1, 317, 318.5, 321.8,],
  C_50th: [69, 83.3, 97.2, 111.2, 124.6, 137.9, 151.1, 163.7, 176.8, 188.9, 200.5,212.2, 222.9, 235.7, 245.7, 255.5, 265.3, 273.9, 281.5, 290.7, 297.6, 304.8, 310.5, 316, 318.6, 321.6, 324.6, 326.1, 328.9, 331.1, 334.6,],
  C_95th: [76.8, 91.5, 105.5, 119.7, 133.5, 147.1, 160.7, 173.7, 187.2, 199.5, 211.3, 223.2, 234.3, 247.5, 257.6, 267.5, 277.3, 285.9, 293.5, 302.7, 309.6, 316.8,322.5, 328, 330.6, 333.6, 336.7, 338.2, 340.8, 343.7, 347.4,],
},
Chitty: { 
  weeks: [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42],
  C_5th: [55.5, 69.1, 82.6, 95.8, 108.8, 121.6, 134.1, 146.4, 158.4, 170.1, 181.5, 192.6, 203.4, 213.8, 223.8, 233.5, 242.9, 251.8, 260.3, 268.3, 275.9, 283.1, 289.8, 296.0, 301.7, 306.9, 311.5, 315.6, 319.2, 322.1, 324.5],
  C_50th: [68.1, 82.2, 96.0, 109.7, 123.1, 136.4, 149.3, 162.0, 174.5, 186.6, 198.5, 210.0, 221.2, 232.1, 242.6, 252.7, 262.5, 271.8, 280.7, 289.2, 297.3, 304.9, 312.0, 318.7, 324.8, 330.4, 335.5, 340.0, 344.0, 347.4, 350.3],
  C_95th: [80.8, 95.2, 109.5, 123.6, 137.5, 151.2, 164.6, 177.7, 190.6, 203.2, 215.5, 227.4, 239.1, 250.4, 261.3, 271.9, 282.1, 291.9, 301.2, 310.2, 318.7, 326.7, 334.3, 341.3, 347.9, 354.0, 359.5, 364.5, 368.9, 372.7, 376.0]
},
Papageorghious: {
  weeks: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
  C_5th: [88.7, 100.6, 112.6, 124.6, 136.6, 148.5, 160.2, 171.9, 183.3, 194.5, 205.4, 216.0, 226.3, 236.2, 245.7, 254.7, 263.2, 271.1, 278.5, 285.3, 291.5, 296.9, 301.7, 305.7, 308.9, 311.2, 312.7],
  C_50th: [97.9, 110.4, 122.9, 135.4, 147.9, 160.3, 172.5, 184.5, 196.3, 207.8, 219.1, 230.0, 240.5, 250.7, 260.4, 269.6, 278.4, 286.6, 294.4, 301.5, 308.1, 314.1, 319.4, 324.1, 328.1, 331.4, 333.9],
  C_95th: [107.1, 120.1, 133.2, 146.3, 159.2, 172.1, 184.7, 197.1, 209.3, 221.2, 232.7, 243.9, 254.7, 265.1, 275.1, 284.6, 293.6, 302.1, 310.2, 317.7, 324.7, 331.2, 337.1, 342.5, 347.3, 351.5, 355.2]
},
Snjiders: {
  weeks: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39],
  C_5th: [102, 111, 120, 130, 141, 151, 162, 173, 184, 195, 206, 217, 227, 238, 248, 257, 266, 274, 282, 288, 294, 299, 303, 306, 308, 309],
  C_50th: [110, 120, 130, 141, 152, 163, 175, 187, 198, 210, 222, 234, 245, 256, 267, 277, 287, 296, 304, 311, 317, 323, 327, 330, 332, 333],
  C_95th: [118, 129, 140, 152, 164, 176, 189, 201, 214, 227, 240, 252, 264, 277, 288, 299, 309, 319, 328, 336, 342, 348, 353, 356, 358, 359]
},
};

export const Microcephaly_HC = {
  P_Acharya : {
  weeks: [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,],
  C_5th: [ 61.2, 75.1, 88.9, 102.8, 117.7, 128.8, 141.6, 153.8, 166.4, 178.3, 189.8, 201.2, 211.4, 224, 233.8, 243.5, 253.3, 261.9, 269.5, 278.7, 285.6, 292.8, 298.5, 304, 306.5, 309.6, 312.6, 314.1, 317, 318.5, 321.8,],
  C_50th: [ 69, 83.3, 97.2, 111.2, 124.6, 137.9, 151.1, 163.7, 176.8, 188.9, 200.5,212.2, 222.9, 235.7, 245.7, 255.5, 265.3, 273.9, 281.5, 290.7, 297.6, 304.8,310.5, 316, 318.6, 321.6, 324.6, 326.1, 328.9, 331.1, 334.6, ],
  C_95th: [76.8, 91.5, 105.5, 119.7, 133.5, 147.1, 160.7, 173.7, 187.2, 199.5, 211.3, 223.2, 234.3, 247.5, 257.6, 267.5, 277.3, 285.9, 293.5, 302.7, 309.6, 316.8, 322.5, 328, 330.6, 333.6, 336.7, 338.2, 340.8, 343.7, 347.4,],},
  Chitty: { },
  Papageorghious: { },
  Snjiders: { },
  WHO: { },};

export const Oligo_AFI = {
  Magann_EF : {
  weeks: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,],
  C_5th: [ 2.8, 3.2, 3.6, 4.1, 4.6, 5.1, 5.5, 5.9, 6.3, 6.7, 7, 7.3, 7.5, 7.6, 7.6, 7.6, 7.5, 7.3, 7.1, 6.8, 6.4, 6, 5.6, 5.1, 4.7, 4.2, 3.7, 3.3,],
  C_50th: [ 5, 5.4, 5.8, 6.3, 6.8, 7.4, 8, 8.7, 9.3, 10, 10.7, 11.4, 12, 12.6, 13, 13.4, 13.6, 13.6, 13.6, 13.3, 12.9, 12.4, 11.8, 11.1, 10.3, 9.4, 8.6, 7.8,],
  C_95th: [ 8.6, 9.1, 9.6, 10.3, 11.1, 12, 12.9, 13.9, 14.9, 15.9, 16.9, 17.8, 18.7, 19.4, 19.7, 20.4, 20.6, 20.6, 20.4, 20, 19.4, 18.7, 17.9, 16.7, 15.9, 14.9, 13.9, 12.9,  ],
}};

export const Oligo_SLVP = {
  Magann_EF : {
  weeks: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,33, 34, 35, 36, 37, 38, 39, 40, 41, ],
  C_5th: [  1.7, 2, 2.3, 2.5, 2.7, 2.8, 2.9, 2.9, 3, 3, 3.1, 3, 3, 3, 3, 2.9, 2.9, 2.9, 2.9, 2.9, 2.8, 2.8, 2.7, 2.6, 2.4, 2.3, 2.1, 1.9,],
  C_50th: [ 2.9, 3.4, 3.6, 3.9, 4.1, 4.3, 4.4, 4.5, 4.6, 4.6, 4.7, 4.7, 4.8, 4.8, 4.8,4.8, 4.8, 4.8, 4.8, 4.8, 4.8, 4.7, 4.7, 4.5, 4.4, 4.2, 3.9, 3.7,],
  C_95th: [ 5, 5.5, 5.9, 6.2, 6.4, 6.6, 6.7, 6.8, 6.8, 6.8, 6.8, 6.8, 6.8, 6.9, 6.9, 6.9, 6.9, 7, 7.1, 7.2, 7.2, 7.2, 7.1, 7, 6.8, 6.6, 6.2, 5.7, ],
}};

export const Trans_Cerebellar_Diameter = {
  Hill_LM : {
  weeks: [ 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,],
  C_5th: [10, 14, 16, 17, 18, 18, 19, 21, 22, 22, 23, 25, 26, 27, 29, 31, 32, 33, 32,33, 33, 36, 37, 40, 46,],
  C_50th: [ 14, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26, 29, 30, 31, 34, 35, 38, 38, 40, 40, 40.5, 43, 45, 48.5, 52,],
  C_95th: [16, 17, 18, 19, 22, 22, 24, 25, 26, 28, 29, 32, 32, 34, 38, 40, 43, 42, 44,44, 47, 55, 55, 55, 58,],
}};

export const Uterine_Artery = {
  O_Gemex : {
  weeks: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,],
  C_5th: [  1.18, 1.11, 1.05, 0.99, 0.94, 0.89, 0.85, 0.81, 0.78, 0.74, 0.71, 0.69, 0.66, 0.64, 0.62, 0.6, 0.58, 0.56, 0.55, 0.54, 0.52, 0.51, 0.5, 0.5, 0.49, 0.48, 0.48, 0.47, 0.47, 0.47, 0.47,],
  C_50th: [ 1.79, 1.68, 1.58, 1.49, 1.41, 1.33, 1.27, 1.2, 1.15, 1.1, 1.05, 1, 0.96,0.93, 0.89, 0.86, 0.84, 0.81, 0.79, 0.77, 0.75, 0.73, 0.71, 0.7, 0.69, 0.68, 0.67, 0.66, 0.65, 0.65, 0.65,],
  C_95th: [ 2.7, 2.53, 2.38, 2.24, 2.11, 1.99, 1.88, 1.79, 1.7, 1.61, 1.54, 1.47, 1.41, 1.35, 1.3, 1.25, 1.21, 1.17, 1.13, 1.1, 1.06, 1.04, 1.01, 0.99, 0.97, 0.95, 0.94, 0.92, 0.91, 0.9, 0.89, ],
}};

export const Uterine_Artery_IP = {
  weeks: [
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
    30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  ],
  C_5th: [
    1.18, 1.11, 1.05, 0.99, 0.94, 0.89, 0.85, 0.81, 0.78, 0.74, 0.71, 0.69,
    0.66, 0.64, 0.62, 0.6, 0.58, 0.56, 0.55, 0.54, 0.52, 0.51, 0.5, 0.5, 0.49,
    0.48, 0.48, 0.47, 0.47, 0.47, 0.47,
  ],
  C_50th: [
    1.79, 1.68, 1.58, 1.49, 1.41, 1.33, 1.27, 1.2, 1.15, 1.1, 1.05, 1, 0.96,
    0.93, 0.89, 0.86, 0.84, 0.81, 0.79, 0.77, 0.75, 0.73, 0.71, 0.7, 0.69, 0.68,
    0.67, 0.66, 0.65, 0.65, 0.65,
  ],
  C_90th: [
    2.7, 2.53, 2.38, 2.24, 2.11, 1.99, 1.88, 1.79, 1.7, 1.61, 1.54, 1.47, 1.41,
    1.35, 1.3, 1.25, 1.21, 1.17, 1.13, 1.1, 1.06, 1.04, 1.01, 0.99, 0.97, 0.95,
    0.94, 0.92, 0.91, 0.9, 0.86,
  ],
};

export const UA = {
  Baschat: {
  weeks: [
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
    30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  ],
  weeks: [
    20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
    39, 40,
  ],
  C_Plus_2_SD: [
    1.83, 1.63, 1.62, 1.36, 1.49, 1.45, 1.37, 1.41, 1.31, 1.49, 1.5, 1.25, 1.31,
    1.26, 1.15, 1.13, 1.29, 1.43, 1.21, 1.35, 1.07,
  ],
  C_Mean: [
    1.2871, 1.2625, 1.2379, 1.2133, 1.1887, 1.1641, 1.1395, 1.1149, 1.0903,
    1.0657, 1.0411, 1.0165, 0.9919, 0.9673, 0.9427, 0.9181, 0.8935, 0.8689,
    0.8443, 0.8197, 0.7951,
  ],
  C_Minus_2_SD: [
    0.79, 0.91, 0.94, 0.88, 0.93, 0.81, 0.85, 0.73, 0.79, 0.73, 0.58, 0.73,
    0.55, 0.58, 0.63, 0.69, 0.57, 0.47, 0.57, 0.67, 0.43,
  ]},
  "Ciobanv et al.": {
    weeks: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41],
  C_5th: [0.955, 0.939, 0.922, 0.906, 0.889, 0.871, 0.854, 0.836, 0.818, 0.800, 0.782, 0.763, 0.744, 0.725, 0.706, 0.687, 0.668, 0.649, 0.630, 0.610, 0.591, 0.572],
    C_50th: [1.218, 1.197, 1.176, 1.155, 1.134, 1.113, 1.092, 1.070, 1.049, 1.028, 1.007, 0.986, 0.965, 0.944, 0.923, 0.902, 0.881, 0.860, 0.839, 0.818, 0.797, 0.776],
    C_95th: [1.553, 1.526, 1.499, 1.472, 1.446, 1.420, 1.395, 1.371, 1.346, 1.322, 1.299, 1.275, 1.252, 1.229, 1.207, 1.184, 1.162, 1.140, 1.118, 1.097, 1.075, 1.053]
  },
  Parracordero : {
    weeks: [23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
  C_5th: [0.93, 0.90, 0.87, 0.84, 0.82, 0.79, 0.77, 0.75, 0.72, 0.70, 0.68, 0.66, 0.64, 0.62, 0.60, 0.58, 0.56, 0.55],
  C_50th: [1.25, 1.21, 1.17, 1.14, 1.10, 1.07, 1.04, 1.01, 0.98, 0.95, 0.92, 0.89, 0.86, 0.84, 0.81, 0.79, 0.76, 0.74],
  C_95th: [1.69, 1.64, 1.59, 1.54, 1.49, 1.45, 1.40, 1.36, 1.32, 1.28, 1.24, 1.20, 1.17, 1.13, 1.10, 1.06, 1.03, 1.00]
  }, };

export const MCA = {
  Baschat : {
  weeks: [
    20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
    39, 40,
  ],
  C_Plus_2_SD: [
    2.24, 2.19, 2.53, 1.97, 2.27, 2.85, 2.95, 3.54, 3.03, 2.64, 3, 2.83, 2.19,
    2.66, 2.33, 2.43, 2.34, 3.42, 2.26, 2.16, 1.71,
  ],
  C_Mean: [
    1.6183, 1.714, 1.7981, 1.8706, 1.9315, 1.9808, 2.0185, 2.0446, 2.0591,
    2.062, 2.0533, 2.033, 2.0011, 1.9576, 1.9025, 1.8358, 1.7575, 1.6676,
    1.5661, 1.453, 1.3283,
  ],
  C_Minus_2_SD: [
    1.28, 1.39, 1.21, 1.33, 1.43, 1.21, 1.23, 0.82, 1.39, 1.4, 1.68, 1.59, 1.43,
    1.14, 1.25, 1.19, 1.26, 0.7, 1.06, 1.12, 0.87,
  ]},
  "Ciobanv et al.": {
    weeks: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41],
    C_5th: [1.162, 1.213, 1.263, 1.313, 1.360, 1.405, 1.445, 1.478, 1.504, 1.521, 1.521, 1.527, 1.503, 1.472, 1.427, 1.369, 1.300, 1.219, 1.129, 1.032, 0.931, 0.827],
    C_50th: [1.486, 1.540, 1.595, 1.651, 1.705, 1.757, 1.805, 1.848, 1.883, 1.909, 1.924, 1.926, 1.915, 1.889, 1.848, 1.791, 1.718, 1.632, 1.532, 1.421, 1.302, 1.177],
    C_95th: [1.901, 1.956, 2.015, 2.075, 2.137, 2.197, 2.255, 2.309, 2.357, 2.395, 2.424, 2.424, 2.441, 2.426, 2.393, 2.342, 2.272, 2.184, 2.078, 1.956, 1.821, 1.674]
  },
  Parracordero : {
    weeks: [ 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
    C_5th: [1.47, 1.53, 1.58, 1.62, 1.64, 1.65, 1.64, 1.62, 1.58, 1.53, 1.46, 1.39, 1.31, 1.22, 1.12, 1.03, 0.93, 0.83],
    C_50th: [2.01, 2.09, 2.16, 2.21, 2.24, 2.25, 2.24, 2.21, 2.16, 2.08, 2.00, 1.90, 1.78, 1.66, 1.53, 1.40, 1.27, 1.14],
    C_95th: [2.74, 2.86, 2.95, 3.02, 3.06, 3.08, 3.06, 3.02, 2.94, 2.85, 2.73, 2.59, 2.43, 2.27, 2.09, 1.91, 1.73, 1.55]
  },
    };

export const CPR = {
  Baschat : {
  weeks: [
    20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
    39, 40,
  ],
  "+2 SD": [
    2.17, 1.94, 2.06, 1.95, 1.97, 2.79, 3.02, 3.34, 3.17, 2.72, 3.44, 2.97,
    2.99, 2.9, 3, 2.69, 2.93, 3.57, 2.72, 2.22, 2.68,
  ],
  Mean: [
    1.2364, 1.3775, 1.5068, 1.6243, 1.73, 1.8239, 1.906, 1.9763, 2.0348, 2.0815,
    2.1164, 2.1395, 2.1508, 2.1503, 2.138, 2.1139, 2.078, 2.0303, 1.9708,
    1.8995, 1.8164,
  ],
  "-2 SD": [
    0.57, 0.94, 0.9, 1.03, 1.09, 0.87, 0.82, 0.9, 1.09, 1, 1.24, 1.61, 1.07,
    1.3, 1.2, 1.33, 1.09, 0.93, 1.08, 1.06, 0.92,
  ],
}};

export const NB = {
  RB_Prakash_Jain : {
  weeks: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  C_5th: [
    1.3, 1.5, 1.7, 2, 2.11, 1.99, 2.7, 2.98, 4.05, 4.2, 4.6, 4.6, 4.6, 4.89,
  ],
  C_50th: [1.8, 2.1, 2.4, 2.55, 3, 3.55, 4.65, 4.6, 5.3, 5.6, 5.8, 6, 6.2, 6.6],
  C_95th: [
    2.32, 2.9, 3.28, 3.51, 4.72,4.95, 5, 5.63, 6.3, 7.35, 7.15, 8, 7.36, 8.05,
  ],
}};

export const IT = {
  Sung_Hee_Yang : {
  weeks: [
    11.0, 11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 12.0, 12.1, 12.2, 12.3, 12.4,
    12.5, 12.6, 13.0, 13.1, 13.2, 13.3, 13.4, 13.5, 13.6,
  ],
  C_5th: [
    1.4, 1.3, 1.3, 1.3, 1.3, 1.3, 1.4, 1.4, 1.5, 1.5, 1.5, 1.7, 1.8, 2, 2.1,
    2.3, 2.3, 2.5, 2.6, 2.6, 2.9,
  ],
  C_50th: [
    1.6, 1.6, 1.7, 1.7, 1.7, 1.8, 1.8, 1.9, 2, 2.1, 2.2, 2.3, 2.3, 2.6, 2.7,
    2.7, 2.9, 2.9, 3, 3, 3.1,
  ],
  C_95th: [
    1.9, 1.9, 1.9, 2.1, 2.1, 2.2, 2.3, 2.5, 2.7, 2.8, 2.8, 2.9, 3.1, 3.3, 3.5,
    3.5, 3.5, 3.5, 3.5, 3.5, 3.6,
  ],
}};

export const NT = {
  E_A_Junior: {
  weeks: [
    11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 12.0, 12.1, 12.2, 12.3, 12.4, 12.5,
    12.6, 13.0, 13.1, 13.2, 13.3, 13.4, 13.5, 13.6,
  ],
  C_5th: [
    0.48, 0.53, 0.56, 0.6, 0.64, 0.68, 0.7, 0.74, 0.77, 0.8, 0.83, 0.86, 0.88,
    0.9, 0.92, 0.94, 0.96, 0.97, 0.98, 0.98,
  ],
  C_50th: [
    1.16, 1.23, 1.26, 1.32, 1.37, 1.42, 1.45, 1.49, 1.53, 1.57, 1.6, 1.63, 1.66,
    1.68, 1.7, 1.71, 1.72, 1.73, 1.73, 1.73,
  ],
  C_95th: [
    1.8, 1.88, 1.92, 1.99, 2.05, 2.11, 2.14, 2.19, 2.23, 2.27, 2.3, 2.33, 2.35,
    2.37, 2.38, 2.38, 2.38, 2.38, 2.37, 2.35,
  ],
}};

export const CRL = {
  L_J_SALOMON : {
  weeks: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  crl: [
    1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
  ],
}};
