import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  fetalGraphReference,
  transCerebellarDiameter,
  ductusVenosus,
  uterineArtery,
  SLVPandAFI,
  tableHeaderName,
  UAandMCAandCPR,
  intracranialtranslucencies,
  nuchaltranslucencies,
  nasalbonelength,
  crownRumpLength,
  chartDataKeys,
  specialReferenceApis,
} from "../utils/constant";
import DynamicTable from "./ShowTable";
import { Line } from "react-chartjs-2";
import { Chart, Filler } from "chart.js";

Chart.register(Filler);

function useScreenWidth(
  isShowTable,
  isShowChart,
  setIsShowTable,
  setIsShowChart
) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (screenWidth < 1370) {
      setIsShowTable(false);
      setIsShowChart(true);
    }
  }, [screenWidth, setIsShowTable]);

  return {
    screenWidth,
    isShowTable,
    isShowChart,
  };
}

function GenerateChart({ chart, setChart }) {
  const chartValue = chart.value;
  const chartWeek = +chart.week;
  const chartType = chart.type;

  const getInitialReference = (chartType) => {
    const savedReference = localStorage.getItem(`${chartType}_reference`);
    switch (chartType) {
      case "BPD":
        return savedReference || "P_Acharya";
      case "HC":
        return savedReference || "P_Acharya";
      case "AC":
        return savedReference || "P_Acharya";
      case "FL":
        return savedReference || "P_Acharya";
      case "EFW":
        return savedReference || "P_Acharya";
      case "TCD":
        return savedReference || "Hill_LM";
      case "SLVP":
        return savedReference || "Magann_EF";
      case "AFI":
        return savedReference || "Magann_EF";
      case "DV":
        return savedReference || "Kessler";
      case "UA":
        return savedReference || "Baschat";
      case "MCA":
        return savedReference || "Baschat";
      case "CPR":
        return savedReference || "Baschat";
      case "Uterine":
        return savedReference || "O_Gemex";
      case "NB":
        return savedReference || "RB_Prakash";
      case "IT":
        return savedReference || "Sung_Hee_Yang";
      case "NT":
        return savedReference || "E_A_Junior";
      case "CRL":
        return savedReference || "L_J_SALOMON";
    }
  };
  const [selectedReference, setSelectedReference] = useState(() =>
    getInitialReference(chartType)
  );
  const [chartData, setChartData] = useState({
    graphData: "",
    graphReference: "",
  });
  const [isShowTable, setIsShowTable] = useState(false);
  const [isShowChart, setIsShowChart] = useState(false);

  const { screenWidth } = useScreenWidth(
    isShowTable,
    isShowChart,
    setIsShowTable,
    setIsShowChart
  );
  const showTableButton = screenWidth < 1370;

  const [tableData, setTableData] = useState([]);
  const [tableHeader, setTableHeader] = useState("");

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "weeks",
          color: "blue",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "mm",
          color: "blue",
        },
      },
    },
  };

  const closeModel = () => {
    setChart({ ...chart, showChart: false });
  };

  const referenceNameMapping = {
    "Ciobanv et al.": "Ciobanv",
  };

  // Modify your API endpoint construction to use the mapping
  const getApiReference = (reference) => {
    return referenceNameMapping[reference] || reference;
  };

  const getTableDataByName = async (selectedReference, chartType, week) => {
    const apiEndpoint = specialReferenceApis[selectedReference]?.[chartType]
      ? `${process.env.REACT_APP_API_KEY}/${specialReferenceApis[selectedReference][chartType]}`
      : `${process.env.REACT_APP_API_KEY}/${getApiReference(
          selectedReference
        ).toLowerCase()}-${chartType.toLowerCase()}s`;

    const response = await axios.get(
      `${apiEndpoint}?sort[0]=${week}:asc&pagination[page]=1&pagination[pageSize]=100`
    );

    const filteredData = response.data.data.map((obj) => {
      const { createdAt, updatedAt, publishedAt, ...rest } = obj.attributes;
      return { ...obj, attributes: rest };
    });
    console.log(filteredData);

    setTableData(filteredData);
    return filteredData;
  };

  const fetchChartData = async (selectedReference, chartType, week) => {
    try {
      const apiEndpoint = specialReferenceApis[selectedReference]?.[chartType]
        ? `${process.env.REACT_APP_API_KEY}/${specialReferenceApis[selectedReference][chartType]}`
        : `${process.env.REACT_APP_API_KEY}/${getApiReference(
            selectedReference
          ).toLowerCase()}-${chartType.toLowerCase()}s`;

      const response = await axios.get(
        `${apiEndpoint}?sort[0]=${week}:asc&pagination[page]=1&pagination[pageSize]=100`
      );
      console.log(response.data.data);

      const keys = chartDataKeys[chartType]?.[selectedReference] ||
        chartDataKeys[chartType]?.["default"] || ["C_5th", "C_50th", "C_95th"];

      const formattedData = {
        weeks: [],
        ...keys.reduce((acc, key) => ({ ...acc, [key]: [] }), {}),
      };

      response.data.data.forEach((item) => {
        formattedData.weeks.push(
          item.attributes.C_Weeks || item.attributes.C_GA_Weeks
        );
        keys.forEach((key) => {
          formattedData[key].push(item.attributes[key]);
        });
      });

      return formattedData;
    } catch (error) {
      console.error("Error fetching chart data:", error);
      return null;
    }
  };

  useEffect(() => {
    localStorage.setItem(`${chartType}_reference`, selectedReference);
  }, [selectedReference, chartType]);

  useEffect(() => {
    const loadChartData = async () => {
      switch (chartType) {
        case "BPD":
          const bpdData = await fetchChartData(
            selectedReference,
            chartType,
            "C_Weeks"
          );

          setChartData({
            graphData: bpdData,
            graphReference: fetalGraphReference[selectedReference],
          });

          getTableDataByName(selectedReference, chartType, "C_Weeks");
          setTableHeader(tableHeaderName.bpd);
          break;
        case "HC":
          const hcData = await fetchChartData(selectedReference, chartType);
          setChartData({
            graphData: hcData,
            graphReference: fetalGraphReference[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_Weeks");
          setTableHeader(tableHeaderName.hc);
          break;
        case "AC":
          const acData = await fetchChartData(selectedReference, chartType);
          setChartData({
            graphData: acData,
            graphReference: fetalGraphReference[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_Weeks");
          setTableHeader(tableHeaderName.ac);
          break;
        case "FL":
          const flData = await fetchChartData(
            selectedReference,
            chartType,
            "C_Weeks"
          );
          setChartData({
            graphData: flData,
            graphReference: fetalGraphReference[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_Weeks");
          setTableHeader(tableHeaderName.fl);
          break;
        case "EFW":
          const EFW = await fetchChartData(
            selectedReference,
            chartType,
            "C_Weeks"
          );
          setChartData({
            graphData: EFW,
            graphReference: fetalGraphReference[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_Weeks");
          setTableHeader(tableHeaderName.efw);
          break;
        case "TCD":
          const TCD = await fetchChartData(
            selectedReference,
            chartType,
            "C_GA_Weeks"
          );
          setChartData({
            graphData: TCD,
            graphReference: transCerebellarDiameter[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_GA_Weeks");
          setTableHeader(tableHeaderName.tcd);
          break;
        case "DV":
          const DV = await fetchChartData(
            selectedReference,
            chartType,
            "C_GA_Weeks"
          );
          setChartData({
            graphData: DV,
            graphReference: ductusVenosus[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_GA_Weeks");
          setTableHeader(tableHeaderName.dv);
          break;
        case "Uterine":
          const Uterine_Artery = await fetchChartData(
            selectedReference,
            chartType,
            "C_GA_Weeks"
          );
          setChartData({
            graphData: Uterine_Artery,
            graphReference: uterineArtery[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_GA_Weeks");
          setTableHeader(tableHeaderName.uterine);
          break;
        case "SLVP":
          const SLVP = await fetchChartData(
            selectedReference,
            chartType,
            "C_GA_Weeks"
          );
          setChartData({
            graphData: SLVP,
            graphReference: SLVPandAFI[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_GA_Weeks");
          setTableHeader(tableHeaderName.slvp);
          break;
        case "AFI":
          const AFI = await fetchChartData(
            selectedReference,
            chartType,
            "C_GA_Weeks"
          );
          setChartData({
            graphData: AFI,
            graphReference: SLVPandAFI[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_GA_Weeks");
          setTableHeader(tableHeaderName.afi);
          break;
        case "UA":
          const UA = await fetchChartData(
            selectedReference,
            chartType,
            "C_Weeks"
          );
          setChartData({
            graphData: UA,
            graphReference: UAandMCAandCPR[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_Weeks");
          setTableHeader(tableHeaderName.ua);
          break;
        case "MCA":
          const MCA = await fetchChartData(
            selectedReference,
            chartType,
            "C_Weeks"
          );
          setChartData({
            graphData: MCA,
            graphReference: UAandMCAandCPR[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_Weeks");
          setTableHeader(tableHeaderName.mca);
          break;
        case "CPR":
          const CPR = await fetchChartData(
            selectedReference,
            chartType,
            "C_Weeks"
          );
          setChartData({
            graphData: CPR,
            graphReference: UAandMCAandCPR[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_Weeks");
          setTableHeader(tableHeaderName.cpr);
          break;
        case "NB":
          const NB = await fetchChartData(
            selectedReference,
            chartType,
            "C_GA_Weeks"
          );
          setChartData({
            graphData: NB,
            graphReference: nasalbonelength[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_GA_Weeks");
          setTableHeader(tableHeaderName.nb);
          break;
        case "IT":
          const IT = await fetchChartData(
            selectedReference,
            chartType,
            "C_GA_Weeks"
          );
          setChartData({
            graphData: IT,
            graphReference: intracranialtranslucencies[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_GA_Weeks");
          setTableHeader(tableHeaderName.it);
          break;
        case "NT":
          const NT = await fetchChartData(
            selectedReference,
            chartType,
            "C_GA_Weeks"
          );
          setChartData({
            graphData: NT,
            graphReference: nuchaltranslucencies[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_GA_Weeks");
          setTableHeader(tableHeaderName.nt);
          break;

        case "CRL":
          const CRL = await fetchChartData(
            selectedReference,
            chartType,
            "C_GA_Weeks"
          );
          setChartData({
            graphData: CRL,
            graphReference: crownRumpLength[selectedReference],
          });
          getTableDataByName(selectedReference, chartType, "C_GA_Weeks");
          setTableHeader(tableHeaderName.crl);
          break;

        default:
          setChartData(null);
      }
    };
    loadChartData();
  }, [chartType, selectedReference]);

  const chartReferences = {
    BPD: ["P_Acharya", "WHO", "Papageorghious", "Snjiders"],
    DV: ["Kessler", "Parracordero"],
    AC: ["P_Acharya", "WHO", "Papageorghious", "Snjiders", "Chitty"],
    HC: ["P_Acharya", "Chitty", "Papageorghious", "Snjiders"],
    FL: ["P_Acharya", "Chitty", "Papageorghious", "Snjiders", "WHO"],
    EFW: ["P_Acharya", "WHO", "Yudkin"],
    TCD: ["Hill_LM"],
    Uterine: ["O_Gemex"],
    SLVP: ["Magann_EF"],
    AFI: ["Magann_EF"],
    UA: ["Baschat", "Ciobanv et al.", "Parracordero"],
    MCA: ["Baschat", "Ciobanv et al.", "Parracordero"],
    CPR: ["Baschat"],
    NB: ["RB_Prakash"],
    IT: ["Sung_Hee_Yang"],
    NT: ["E_A_Junior"],
    CRL: ["L_J_SALOMON"],
  };

  const getDataSourceForChart = (chartType) => {
    const references = chartReferences[chartType] || [];
    return references.reduce((acc, ref) => {
      acc[ref] = {};
      return acc;
    }, {});
  };

  const ReferenceSelector = () => {
    const dataSource = getDataSourceForChart(chartType);

    return (
      <select
        value={selectedReference}
        onChange={(e) => setSelectedReference(e.target.value)}
        className="px-4 py-2 border rounded text-gray-700"
      >
        {Object.keys(dataSource).map((ref) => (
          <option key={ref} value={ref}>
            {ref}
          </option>
        ))}
      </select>
    );
  };

  useEffect((e) => {
    const close = (e) => {
      if (e.key === "Escape") {
        closeModel();
      }
    };

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  let chartdata = chartData.graphData;
  let labels = chartdata ? chartdata?.weeks : [];
  let tableLabels = Object.keys(chartdata);

  const borderColors = [
    "rgba(255, 0, 0, 1)", // Red for upper line
    "rgba(0, 128, 0, 1)", // Green for middle line
    "rgba(255, 0, 0, 1)", // Red for lower line
  ];
  const backgroundColor = [
    "rgba(255, 0, 0, 0.2)", // Red with transparency
    "rgba(0, 128, 0, 0.2)", // Green with transparency
    "rgba(255, 0, 0, 0.2)", // Red with transparency
  ];
  let datasets = tableLabels
    .filter((label) => label !== "weeks")
    .map((label, index) => {
      return {
        label,
        data: chartdata[label],
        borderColor: borderColors[index % borderColors.length],
        backgroundColor: backgroundColor[index % backgroundColor.length],
        borderWidth: 2, // Optional: make lines more visible
      };
    });

  // Find the closest week in the labels array for the dynamic point
  const findClosestWeekIndex = (labels, targetWeek) => {
    // Convert targetWeek to a number to ensure proper comparison
    const targetWeekNum = parseFloat(targetWeek);
    
    // If exact match found, return that index
    const exactIndex = labels.findIndex(week => parseFloat(week) === targetWeekNum);
    if (exactIndex !== -1) return exactIndex;
    
    // If no exact match, find the closest value
    return labels.reduce((closestIdx, week, idx) => {
      const currentWeek = parseFloat(week);
      const currentDiff = Math.abs(currentWeek - targetWeekNum);
      const closestDiff = Math.abs(parseFloat(labels[closestIdx]) - targetWeekNum);
      
      return currentDiff < closestDiff ? idx : closestIdx;
    }, 0);
  };

  // Using our helper function to find the best index
  const dynamicPointIndex = findClosestWeekIndex(labels, chartWeek);
  
  // Create a data array with a single point at the correct week
  const dynamicPointData = Array(labels.length).fill(null);
  if (dynamicPointIndex !== -1 && chartValue) {
    dynamicPointData[dynamicPointIndex] = chartValue;
  }
  
  const dynamicPointDataset = {
    label: chartType,
    backgroundColor: "red",
    borderColor: "red",
    data: dynamicPointData,
    pointBackgroundColor: "red",
    pointRadius: 5,
    pointHoverRadius: 10,
    showLine: false, // Set to false to only show the point without connecting lines
  };
  let newDataset = chartValue
    ? [...datasets, dynamicPointDataset]
    : [...datasets];

  // if (chartData.graphData) {
  //   const weeks = chartData.graphData.weeks;

  //   if (chartType === "Uterine") {
  //     const weeks = chartData.graphData.weeks;
  //     const c_95th = chartData.graphData.C_95th;
  //     const maxC95th = Math.max(...c_95th);

  //     const formattedData = weeks.map((week, index) => ({
  //       x: week,
  //       y: Math.round(maxC95th),
  //     }));
  //     const newData = {
  //       label: "Improper Placentation",
  //       backgroundColor: "rgba(54, 162, 235, 0.2)",
  //       fill: 2,
  //       data: formattedData,
  //       borderWidth: 0,
  //       radius: 0,
  //     };
  //     newDataset = [...newDataset, newData];
  //   } else if (chartType === "SLVP") {
  //     const c_95th = chartData.graphData.C_95th;
  //     const maxC95th = Math.max(...c_95th);

  //     const formattedDataAbove = weeks.map((week, index) => ({
  //       x: week,
  //       y: Math.round(maxC95th) + 1,
  //     }));

  //     const formattedDataBelow = weeks.map((week, index) => ({
  //       x: week,
  //       y: 1,
  //     }));

  //     const newDataAbove = {
  //       label: "Polyhydramnios",
  //       backgroundColor: "rgba(54, 162, 235, 0.2)",
  //       fill: 2,
  //       data: formattedDataAbove,
  //       borderWidth: 0,
  //       radius: 0,
  //     };

  //     const newDataBelow = {
  //       label: "Oligohydramnios",
  //       backgroundColor: "rgba(255, 99, 132, 0.2)",
  //       fill: 0,
  //       data: formattedDataBelow,
  //       borderWidth: 0,
  //       radius: 0,
  //     };
  //     newDataset = [...newDataset, newDataAbove, newDataBelow];
  //   } else if (chartType === "UA") {
  //     const plus2sd = chartData.graphData.C_Plus_2_SD;
  //     const maxPlus2sd = Math.max(...plus2sd);

  //     const formattedDataAbove = weeks.map((week, index) => ({
  //       x: week,
  //       y: Math.round(maxPlus2sd) + 1,
  //     }));

  //     const newDataAbove = {
  //       label: "> +2 SD",
  //       backgroundColor: "rgba(255, 99, 132, 0.2)",
  //       fill: 0,
  //       data: formattedDataAbove,
  //       borderWidth: 0,
  //       radius: 0,
  //     };
  //     newDataset = [...newDataset, newDataAbove];
  //   } else if (chartType === "MCA" || chartType === "CPR") {
  //     const formattedDataBelow = weeks.map((week, index) => ({
  //       x: week,
  //       y: 0.5,
  //     }));

  //     const newDataBelow = {
  //       label: "< -2 SD",
  //       backgroundColor: "rgba(255, 99, 132, 0.2)",
  //       fill: 2,
  //       data: formattedDataBelow,
  //       borderWidth: 0,
  //       radius: 0,
  //     };
  //     newDataset = [...newDataset, newDataBelow];
  //   }
  // }
  if (chartData.graphData) {
    const weeks = chartData.graphData.weeks;

    switch (chartType) {
      case "Uterine":
        const c_95th =
          chartData.graphData[chartDataKeys[chartType][selectedReference][2]];
        const maxC95th = Math.max(...c_95th);

        const formattedData = weeks.map((week) => ({
          x: week,
          y: Math.round(maxC95th),
        }));

        newDataset = [
          ...newDataset,
          {
            label: "Improper Placentation",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            fill: 2,
            data: formattedData,
            borderWidth: 0,
            radius: 0,
          },
        ];
        break;

      case "SLVP":
        const slvp_95th =
          chartData.graphData[chartDataKeys[chartType][selectedReference][2]];
        // console.log();

        const maxSlvp95th = Math.max(...slvp_95th);

        newDataset = [
          ...newDataset,
          {
            label: "Polyhydramnios",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            fill: 2,
            data: weeks.map((week) => ({
              x: week,
              y: Math.round(maxSlvp95th) + 1,
            })),
            borderWidth: 0,
            radius: 0,
          },
          {
            label: "Oligohydramnios",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            fill: 0,
            data: weeks.map((week) => ({
              x: week,
              y: 1,
            })),
            borderWidth: 0,
            radius: 0,
          },
        ];
        break;

      // case "UA":
      //   const plus2sd = chartData.graphData[chartDataKeys[chartType][selectedReference][0]];
      //   // console.log(plus2sd);
      //   const maxPlus2sd = Math.max(...plus2sd);

      //   newDataset = [...newDataset, {
      //     label: "> +2 SD",
      //     backgroundColor: "rgba(255, 99, 132, 0.2)",
      //     fill: 0,
      //     data: weeks.map(week => ({
      //       x: week,
      //       y: Math.round(maxPlus2sd) + 1,
      //     })),
      //     borderWidth: 0,
      //     radius: 0,
      //   }];
      //   break;

      case "MCA":
      case "CPR":
        newDataset = [
          ...newDataset,
          {
            label: "< -2 SD",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            fill: 2,
            data: weeks.map((week) => ({
              x: week,
              y: 0.5,
            })),
            borderWidth: 0,
            radius: 0,
          },
        ];
        break;
    }
  }

  const data = {
    labels,
    datasets: newDataset,
  };

  const showTable = (e) => {
    setIsShowTable(true);
    setIsShowChart(false);
  };

  const showChart = (e) => {
    setIsShowChart(true);
    setIsShowTable(false);
  };

  return (
    <>
      {chart.showChart && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1]"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="relative bg-white rounded-md shadow w-[calc(100%_-_60px)] h-[calc(100vh_-_60px)] px-5 py-5 overflow-y-auto">
            <div className="pb-0.5 w-full text-center">
              {tableHeader && (
                <p className="font-poppinsMedium text-2xl text-[#03254c]">
                  {tableHeader}
                </p>
              )}
            </div>

            <div className="absolute z-20 pr-5 pt-5 rounded-t top-0 right-0">
              <button
                type="button"
                className=""
                data-modal-hide="small-modal"
                onClick={closeModel}
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div
              className={`mt-4 gap-x-3 ${showTableButton ? "flex" : "hidden"}`}
            >
              <button
                type="button"
                className={`px-4 py-3 text-white bg-blue-500 border text-lg rounded  ${
                  isShowChart ? "bg-blue-900" : "bg-blue-500"
                }`}
                onClick={showChart}
              >
                Show Chart
              </button>

              <button
                type="button"
                className={`px-4 py-3 text-white bg-blue-500 text-lg border  rounded ${
                  isShowTable ? "bg-blue-900" : "bg-blue-500"
                }`}
                onClick={showTable}
              >
                Show Table
              </button>
            </div>
            <div
              className={`${
                showTableButton ? "hidden" : "flex"
              } laptop-small:flex-row flex-col mt-5`}
            >
              {tableData.length !== 0 && <DynamicTable data={tableData} />}
              <div className="pt-1 pl-2 laptop-big:w-3/5 laptop-small:w-1/2 w-full h-[calc(100vh_-_170px)] overflow-y-auto">
                <Line options={options} data={data} />
                <div className="ml-10 mb-1">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Select Reference
                  </label>
                  <ReferenceSelector />
                </div>
                <div className="ml-12 mt-5">
                  {chartData.graphReference.abstract && (
                    <p
                      className="text-sm pb-1"
                      dangerouslySetInnerHTML={{
                        __html: chartData.graphReference.abstract,
                      }}
                    ></p>
                  )}
                  {chartData.graphReference.header && (
                    <p
                      className="font-poppinsMedium text-xl pb-2"
                      dangerouslySetInnerHTML={{
                        __html: chartData.graphReference.header,
                      }}
                    ></p>
                  )}
                  {chartData.graphReference.author && (
                    <p className="text-[#03254c] font-poppinsMedium">
                      {chartData.graphReference.author}
                    </p>
                  )}
                  <div className="flex gap-x-2">
                    {chartData.graphReference.publish && (
                      <p>{chartData.graphReference.publish}</p>
                    )}
                    {chartData.graphReference.url && (
                      <a
                        className="text-blue-700"
                        href={`${chartData.graphReference.url}`}
                        target="_blank"
                      >
                        {chartData.graphReference.url}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                showTableButton ? "flex" : "hidden"
              } laptop-small:flex-row flex-col mt-5`}
            >
              {tableData.length !== 0 && isShowTable && (
                <DynamicTable data={tableData} />
              )}
              {isShowChart && (
                <div className="pt-3 pl-4 laptop-big:w-3/5 laptop-small:w-1/2 w-full h-[calc(100vh_-_170px)] overflow-y-auto">
                  <Line options={options} data={data} />
                  <div className="ml-12 mt-5">
                    {chartData.graphReference.abstract && (
                      <p
                        className="text-sm pb-1 font-poppinsBold"
                        dangerouslySetInnerHTML={{
                          __html: chartData.graphReference.abstract,
                        }}
                      ></p>
                    )}
                    {chartData.graphReference.header && (
                      <p className="font-poppinsMedium text-xl pb-2">
                        {chartData.graphReference.header}
                      </p>
                    )}
                    {chartData.graphReference.author && (
                      <p className="text-[#03254c] font-poppinsMedium">
                        {chartData.graphReference.author}
                      </p>
                    )}
                    <div className="flex gap-x-2 font-poppinsBold">
                      {chartData.graphReference.publish && (
                        <p>{chartData.graphReference.publish}</p>
                      )}
                      {chartData.graphReference.url && (
                        <a
                          className="text-blue-700 font-poppinsBold"
                          href={`${chartData.graphReference.url}`}
                          target="_blank"
                        >
                          {chartData.graphReference.url}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default GenerateChart;
