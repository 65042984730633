import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
} from "@mui/material";
import { calculateTrisomyRisk } from "./RiskTrisomy";

const STSCalculator = ({ onLRChange, dateOfBirth }) => {
  const [risk, setRisk] = useState(null);
  const [open, setOpen] = useState(false);
  const [activeMarkerIndex, setActiveMarkerIndex] = useState(-1); // Tracks current marker
  const [stsData, setStsData] = useState({
    marker1: { status: "Unknown", lr: 1 },
    marker2: { status: "Unknown", lr: 1 },
    marker3: { status: "Unknown", lr: 1 },
    marker4: { status: "Unknown", lr: 1 },
    marker5: { status: "Unknown", lr: 1 },
    marker6: { status: "Unknown", lr: 1 },
    marker7: { status: "Unknown", lr: 1 },
    marker8: { status: "Unknown", lr: 1 },
  });
  const [combinedLR, setCombinedLR] = useState(1);

  const markers = [
    {
      id: "marker1",
      label: "Intracardiac Echogenic Focus",
      values: { Present: 5.83, Absent: 0.8 },
    },
    {
      id: "marker2",
      label: "Mild Hydronephrosis",
      values: { Present: 7.63, Absent: 0.92 },
    },
    {
      id: "marker3",
      label: "Short Femur",
      values: { Present: 3.72, Absent: 0.8 },
    },
    {
      id: "marker4",
      label: "Echogenic Bowel",
      values: { Present: 11.44, Absent: 0.9 },
    },
    {
      id: "marker5",
      label: "Increased Nuchal Fold",
      values: { Present: 23.3, Absent: 0.8 },
    },
    {
      id: "marker6",
      label: "Absent/Hypoplastic Nasal Bone",
      values: { "Hypoplastic or absent": 21.48, "Normal size": 0.71 },
      customOptions: true,
    },
    {
      id: "marker7",
      label: "Aberrant Right Subclavian Artery",
      values: { Present: 23.27, Absent: 0.46 },
    },
    {
      id: "marker8",
      label: "Ventriculomegaly",
      values: { Present: 27.52, Absent: 0.94 },
    },
  ];

  const handleMarkerChange = (marker, status, lr) => {
    const updatedStsData = {
      ...stsData,
      [marker]: { status, lr },
    };
    setStsData(updatedStsData);

    const totalLR = Object.values(updatedStsData).reduce(
      (acc, curr) => acc * curr.lr,
      1
    );
    const roundedLR = Number(totalLR.toFixed(2));
    setCombinedLR(roundedLR);
    onLRChange(roundedLR);

    if (dateOfBirth) {
      const calculatedRisk = calculateTrisomyRisk(dateOfBirth, roundedLR);
      setRisk(calculatedRisk);
    }
  };

  // Updated useEffect hook for spacebar handling
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the pressed key is spacebar and the modal is open
      if (event.code === "Space" && open) {
        event.preventDefault(); // Prevent page scrolling

        // Determine the next marker index to update
        const nextIndex = (activeMarkerIndex + 1) % markers.length;
        setActiveMarkerIndex(nextIndex);

        const currentMarker = markers[nextIndex];
        const firstOption = currentMarker.customOptions
          ? "Normal size"
          : "Absent";

        // Update the selected value and likelihood ratio
        handleMarkerChange(
          currentMarker.id,
          firstOption,
          currentMarker.values[firstOption]
        );
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeMarkerIndex, markers, open]);

  const resetCalculator = () => {
    const resetData = Object.keys(stsData).reduce((acc, key) => {
      acc[key] = { status: "Unknown", lr: 1 };
      return acc;
    }, {});
    setStsData(resetData);
    setCombinedLR(1);
    onLRChange(1);
    setRisk(null);
    setActiveMarkerIndex(-1);
  };

  const MarkerRow = ({ marker }) => (
    <div className="flex items-center justify-between mb-10 pr-10">
      <label className="text-base font-poppinsMedium text-[#03254c] min-w-[200px]">
        {marker.label}
      </label>
      <div className="flex gap-x-2">
        <select
          value={stsData[marker.id]?.status || ""}
          onChange={(e) => {
            const status = e.target.value;
            const lr = status === "Unknown" ? 1 : marker.values[status];
            handleMarkerChange(marker.id, status, lr);
          }}
          className="w-36 border border-[#bcbcbc] rounded-md py-1.5 px-2 text-sm"
        >
          <option value="Unknown">Unknown</option>
          {marker.customOptions ? (
            <>
              <option value="Normal size">Normal</option>
              <option value="Hypoplastic or absent">
                Hypoplastic or absent
              </option>
            </>
          ) : (
            <>
              <option value="Absent">Absent</option>
              <option value="Present">Present</option>
            </>
          )}
        </select>
        <input
          type="text"
          value={stsData[marker.id]?.lr || ""}
          readOnly
          className="w-20 border border-[#bcbcbc] rounded-md py-1.5 px-2 text-sm bg-gray-50"
          placeholder="LR"
        />
      </div>
    </div>
  );

  return (
    <>
      <div className="w-full">
        {/* Main row with both LR section and Trisomy Calculator button */}
        <div className="flex flex-wrap items-center gap-x-4 gap-y-2 mb-2">
          {/* LR and Calculate section */}
          <div className="flex items-center gap-x-4">
            <Tooltip title="Likelihood Ratio of Combination">
              <label className="text-lg font-poppinsMedium text-[#03254c]">
                LR
              </label>
            </Tooltip>
            <button
              variant="contained"
              onClick={() => setOpen(true)}
              className="laptop-small:w-32 w-32 border py-2 px-4 text-lg text-white bg-[#0d1838] rounded"
            >
              Calculate
            </button>
            <input
              type="text"
              value={combinedLR}
              readOnly
              className="laptop-small:w-32 w-16 border border-[#bcbcbc] rounded-md py-2 px-2 text-base focus:border-[#909090]"
            />
          </div>

          {/* Trisomy Risk Calculator button - in same row */}
          <button
            type="button"
            className="px-8 py-2 text-white bg-[#0d1838] border rounded text-lg md:flex-grow lg:flex-grow-0"
            onClick={() =>
              window.open(
                "https://fetalmedicine.org/research/assess/trisomies",
                "_blank"
              )
            }
          >
            Trisomy Risk Calculator (FMF)
          </button>
        </div>

        {/* Risk message - always full width */}
        {risk && (
          <div className="flex items-center px-3 py-1 bg-[#cfdcff] border border-[#03254c] border-dashed rounded w-full">
            <Tooltip title="Risk based on maternal age and STS markers">
              <label className="text-lg font-poppinsMedium text-[#03254c]">
                Risk For Trisomy 21 is 1: {risk}
              </label>
            </Tooltip>
          </div>
        )}
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogContent className="p-6">
          <DialogTitle className="text-center font-poppinsMedium text-[#03254c] border-b border-gray-200 pb-3 text-xl">
            Second Trimester Soft Markers Parameters Calculator
          </DialogTitle>

          <button
            type="button"
            className="absolute z-20 right-5 top-5"
            onClick={() => setOpen(false)}
          >
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>

          {/* Responsive grid - changes to single column on tablet */}
          <div className="grid grid-cols-2 gap-x-8 mt-6 mb-6 md:grid-cols-1 lg:grid-cols-2">
            <div className="flex flex-col">
              {/* First set of markers remains the same on desktop, but contains all markers on tablet */}
              {window.innerWidth >= 992
                ? markers
                    .slice(0, 4)
                    .map((marker) => (
                      <MarkerRow key={marker.id} marker={marker} />
                    ))
                : markers.map((marker) => (
                    <MarkerRow key={marker.id} marker={marker} />
                  ))}
            </div>
            {/* This div only appears on desktop screens */}
            {window.innerWidth >= 992 && (
              <div className="flex flex-col">
                {markers.slice(4, 8).map((marker) => (
                  <MarkerRow key={marker.id} marker={marker} />
                ))}
              </div>
            )}
          </div>

          {/* Footer section */}
          <div className="flex flex-col border-t border-gray-200 w-full pt-4">
            {/* Age Related Risk - separate row on tablet */}
            <div className="w-full mb-4 md:mb-4 lg:mb-0">
              {risk && (
                <div className="flex items-center justify-center px-3 py-1 bg-[#cfdcff] border border-[#03254c] border-dashed rounded mb-2">
                  <Tooltip title="Risk based on maternal age and STS markers">
                    <label className="text-lg font-poppinsMedium text-[#03254c] text-center">
                      Age Related Risk: {risk}
                    </label>
                  </Tooltip>
                </div>
              )}
            </div>

            {/* Second row with LR Combination and buttons in one row on tablet */}
            <div className="flex lg:flex-row md:flex-row flex-col w-full">
              {/* LR Combination */}
              <div className="flex items-center gap-x-2 lg:flex-1 md:flex-1 justify-center">
                <label className="text-lg font-poppinsMedium text-[#03254c]">
                  LR for Combination:
                </label>
                <input
                  type="text"
                  value={combinedLR}
                  readOnly
                  className="w-32 border border-[#bcbcbc] rounded-md py-2 px-3 text-lg bg-gray-50 text-center"
                  placeholder="Combined LR"
                />
              </div>

              {/* Buttons */}
              <div className="lg:flex-1 md:flex-1 flex justify-end space-x-4 mt-2 md:mt-0">
                <button
                  variant="contained"
                  onClick={resetCalculator}
                  className="text-white bg-[#0d1838] border rounded px-4 py-2"
                >
                  Reset
                </button>
                <button
                  variant="contained"
                  onClick={() => setOpen(false)}
                  className="text-white bg-[#0d1838] border rounded px-4 py-2"
                  size="small"
                >
                  Close
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default STSCalculator;
