import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ScanOFeLogo from "../assets/ScanOFeMainLogo.png";
import TemplateDialog from '../components//TemplateDialog';

const Summary = ({ 
  name,
  dateOfBirth,
  date,
  dueDate,
  selectedOption,
  tableData,
  checkedDiagnoses,
  checkedSyndromes
}) => {
    const [loading, setLoading] = useState(true);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [pdfData, setPdfData] = useState(null);
    const [syndromesData, setSyndromesData] = useState([]);
    const [showTemplateDialog, setShowTemplateDialog] = useState(false);
    const [showSummary, setShowSummary] = useState(true); // Add this state

    // Modify the template dialog handler
  const handleShowTemplate = () => {
    setShowSummary(false);
    setShowTemplateDialog(true);
  };

  // Add handler for closing template
  const handleCloseTemplate = () => {
    setShowTemplateDialog(false);
    setShowSummary(true);
  };

    useEffect(() => {
        if (name && dueDate.dueDate) {
          fetchSyndromeData();
        } else {
          setLoading(false);
        }
      }, [name, dueDate.dueDate]);

    const fetchSyndromeData = async () => {
      try {
        setLoading(true);
    
        if (name === "") {
          setLoading(false);
          return toast.error("Please provide a valid name");
        }
        if (!dueDate.dueDate) {
          setLoading(false);
          return toast.error("Please provide a valid date");
        }

        // Fetch syndrome data
        const promises = Object.entries(checkedSyndromes).map(async (data) => {
          const getSyndromeData = await axios.get(
            `${process.env.REACT_APP_API_KEY}/syndromess/?filters[name][$eq]=${data[1].name}&populate=investigations`
          );

          const response = getSyndromeData.data.data[0].attributes.investigations;
          const investigationData = response.data.map((response) => ({
            syndromeName: data[1].name,
            investigation: response.attributes.name,
          }));
          return investigationData;
        });

        const results = (await Promise.all(promises)).flat();
        setSyndromesData(results);
        
        // Format data for both PDF and UI
        const formattedData = formatPdfData();
        setPdfData(formattedData);
        
        // Also generate PDF for download option
        generatePDF(results);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to generate report");
        setLoading(false);
      }
    };

    const formatPdfData = () => {
      // Format dates
      const newDate = new Date(date);
      const dobDate = new Date(dateOfBirth);
      const week = dueDate.weeks;

      function formatDateDDMMYYYY(date) {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      }

      const formattedDate = formatDateDDMMYYYY(newDate);
      const formattedDOB = formatDateDDMMYYYY(dobDate);
      let ebo = selectedOption === "lmp" ? "Last Menstrual Period" : "GA to EDD";

      // Prepare biometry table data
      let biometryRows = [];
      for (const key in tableData) {
        if (tableData[key] !== "" && key !== "PatientID") {
          biometryRows.push({
            parameter: key,
            value: tableData[key],
            week: week
          });
        }
      }

      // Prepare diagnoses and syndromes data
      const selectedDiagnoses = Object.values(checkedDiagnoses)
        .filter((item) => item.checked)
        .map((item) => item.name);

      const selectedSyndromes = Object.values(checkedSyndromes)
        .filter((item) => item.checked)
        .map((item) => item.name);

        return {
            patientInfo: {
              name,
              dob: formattedDOB,
              ebo,
              lmpDate: formattedDate,
              weeks: dueDate.weeks,
              days: dueDate.days,
              eddByDate: dueDate.dueDate
            },
            biometryData: biometryRows,
            diagnoses: selectedDiagnoses,
            syndromes: selectedSyndromes
          };
    };

    const generatePDF = async (results) => {
      try {
        const doc = new jsPDF();
        const image = new Image();
        image.src = ScanOFeLogo;
        const defaultFontsize = 14;
        let y = 10;

        if (!name || !dueDate.dueDate) return;

        // Add logo
        doc.addImage(image, "png", 80, 5, 50, 10);
        y += 20;

        // Add patient information
        let ebo = selectedOption === "lmp" ? "Last Menstrual Period" : "GA to EDD";

        const newDate = new Date(date);
        const dobDate = new Date(dateOfBirth);
        const week = dueDate.weeks;

        function formatDateDDMMYYYY(date) {
          const day = date.getDate().toString().padStart(2, "0");
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }

        const formattedDate = formatDateDDMMYYYY(newDate);
        const formattedDOB = formatDateDDMMYYYY(dobDate);

        // Patient details
        doc.setFontSize(defaultFontsize);
        doc.text("Name: " + name, 10, y);
        y += 10;
        doc.text("DOB: " + formattedDOB, 10, y);

        if (ebo === "Last Menstrual Period") {
          doc.text("LMP Date: " + formattedDate, 120, y);
        } else {
          doc.text("Weeks: " + dueDate.weeks, 120, y);
          doc.text("Days: " + dueDate.days, 150, y);
        }

        y += 10;
        doc.text("Estimate Based On: " + ebo, 10, y);
        doc.text("EDD by Date: " + dueDate.dueDate, 120, y);
        y += 20;

        // Prepare biometry table data
        doc.setFontSize(12);
        let biometryRows = [];

        for (const key in tableData) {
          if (tableData[key] !== "" && key !== "PatientID") {
            biometryRows.push([key, tableData[key], week]);
          }
        }

        // Add biometry table if there's data
        if (biometryRows.length > 0) {
          doc.autoTable({
            head: [["Fetal Biometry", "Value", "Week"]],
            body: biometryRows,
            startY: y,
            theme: "grid",
            headStyles: {
              fillColor: "#03254c",
              textColor: "#FFFFFF",
              halign: "center",
              valign: "middle",
            },
            columnStyles: {
              0: { halign: "center", cellWidth: 60 },
              1: { halign: "center", cellWidth: 60 },
              2: { halign: "center", cellWidth: 60 },
            },
            margin: { left: 15 },
          });
        }

        // Get current Y position after first table
        y = doc.previousAutoTable ? doc.previousAutoTable.finalY + 15 : y + 20;

        // Prepare diagnoses and syndromes data
        const selectedDiagnoses = Object.values(checkedDiagnoses)
          .filter((item) => item.checked)
          .map((item) => item.name);

        const selectedSyndromes = Object.values(checkedSyndromes)
          .filter((item) => item.checked)
          .map((item) => item.name);

        // Add diagnoses and syndromes table if there's data
        if (selectedDiagnoses.length > 0 || selectedSyndromes.length > 0) {
          const tableRows = [];
          const maxLength = Math.max(selectedDiagnoses.length, selectedSyndromes.length);

          for (let i = 0; i < maxLength; i++) {
            const diagnosis = selectedDiagnoses[i] || "";
            const syndrome = selectedSyndromes[i] || "";

            // Filter and join investigations
            const investigationsForSyndrome = results
              .filter((item) => item.syndromeName === syndrome)
              .map((item) => item.investigation || "")
              .filter(Boolean);

            const combinedInvestigations = investigationsForSyndrome.join(", ") || "";

            // Only add row if at least one field has data
            if (diagnosis || syndrome || combinedInvestigations) {
              tableRows.push([diagnosis, syndrome, combinedInvestigations]);
            }
          }

          // Check if we need a new page
          if (y > doc.internal.pageSize.height - 60) {
            doc.addPage();
            y = 20;
          }

          // Add the table with fixed dimensions
          doc.autoTable({
            head: [["Positive USG Findings", "Possible Syndromes Associated", "Investigation Needed"]],
            body: tableRows,
            startY: y,
            theme: "grid",
            headStyles: {
              fillColor: "#03254c",
              textColor: "#FFFFFF",
              halign: "center",
              valign: "middle",
            },
            columnStyles: {
              0: { halign: "center", cellWidth: 60 },
              1: { halign: "center", cellWidth: 60 },
              2: { halign: "center", cellWidth: 60 },
            },
            margin: { left: 15 },
          });
        }

        // Add disclaimer
        doc.setFontSize(10);
        const totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          const disclaimerText =
            "Disclaimer: ScanOFe is software to aid you in your clinical guidance to reach for fetal diagnosis. " +
            "We are not legally liable for any clinical error of judgment on the clinician or user side or any possible deficiencies in ScanOFe.";

          const disclaimerLines = doc.splitTextToSize(
            disclaimerText,
            doc.internal.pageSize.width - 20
          );

          doc.text(disclaimerLines, 10, doc.internal.pageSize.height - 10);
        }

        // Generate PDF blob
        const pdfBlob = doc.output('blob');
        setPdfBlob(pdfBlob);
        setLoading(false);
      } catch (error) {
        console.error("Error generating PDF:", error);
        toast.error("Failed to generate PDF");
        setLoading(false);
      }
    };

    // Component for rendering the PDF-like UI
    const PdfView = () => {
      if (!pdfData) return null;
      
      const { patientInfo, biometryData, diagnoses, syndromes } = pdfData;

      // Create findings table data
      const findingsTableData = [];
      const maxLength = Math.max(diagnoses.length, syndromes.length);

      for (let i = 0; i < maxLength; i++) {
        const diagnosis = diagnoses[i] || "";
        const syndrome = syndromes[i] || "";

        // Filter and join investigations
        const investigationsForSyndrome = syndromesData
          .filter((item) => item.syndromeName === syndrome)
          .map((item) => item.investigation || "")
          .filter(Boolean);

        const combinedInvestigations = investigationsForSyndrome.join(", ") || "";

        // Only add row if at least one field has data
        if (diagnosis || syndrome || combinedInvestigations) {
          findingsTableData.push({
            diagnosis,
            syndrome,
            investigations: combinedInvestigations
          });
        }
      }

      

      return (
        <div >
          {/* Patient Information Section */}
          <div className="flex justify-center mb-5 mt-5">
      <img src={ScanOFeLogo} alt="ScanOFe Logo" style={{ width: '200px' }} />
    </div>

          <div className="grid grid-cols-2 mb-6">

            <div>
              <p className="text-base font-medium">Name: {patientInfo.name}</p>
              <p className="text-base">DOB: {patientInfo.dob}</p>
              <p className="text-base">Estimate Based On: {patientInfo.ebo}</p>
            </div>
            <div>
              <p className="text-base">LMP Date: {patientInfo.lmpDate}</p>
              <p className="text-base">EDD by Date: {patientInfo.eddByDate}</p>
            </div>
          </div>
          
          {/* Biometry Table */}
          {biometryData.length > 0 && (
            <div className="mb-8">
              <table className="w-full border-collapse">
                <thead>
                  <tr>
                    <th className="py-2 px-4 text-center border border-gray-300 bg-[#052e5c] text-white">
                      Fetal Biometry
                    </th>
                    <th className="py-2 px-4 text-center border border-gray-300 bg-[#052e5c] text-white">
                      Value
                    </th>
                    <th className="py-2 px-4 text-center border border-gray-300 bg-[#052e5c] text-white">
                      Week
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {biometryData.map((row, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                      <td className="py-2 px-4 text-center border border-gray-300 text-[#052e5c] font-medium">
                        {row.parameter}
                      </td>
                      <td className="py-2 px-4 text-center border border-gray-300">
                        {row.value}
                      </td>
                      <td className="py-2 px-4 text-center border border-gray-300 text-[#052e5c]">
                        {patientInfo.weeks}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          
          {/* Findings Table */}
          {findingsTableData.length > 0 && (
            <div className="mb-8">
              <table className="w-full border-collapse">
                <thead>
                  <tr>
                    <th className="py-2 px-4 text-center border border-gray-300 bg-[#052e5c] text-white">
                      Positive USG Findings
                    </th>
                    <th className="py-2 px-4 text-center border border-gray-300 bg-[#052e5c] text-white">
                      Possible Syndromes Associated
                    </th>
                    <th className="py-2 px-4 text-center border border-gray-300 bg-[#052e5c] text-white">
                      Investigation Needed
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {findingsTableData.map((row, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                      <td className="py-2 px-4 text-center border border-gray-300">
                        {row.diagnosis}
                      </td>
                      <td className="py-2 px-4 text-center border border-gray-300">
                        {row.syndrome}
                      </td>
                      <td className="py-2 px-4 text-center border border-gray-300">
                        {row.investigations}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          
          {/* Disclaimer */}
          <div className="mt-8 text-xs text-gray-600 border-t pt-4">
            <p>
              Disclaimer: ScanOFe is software to aid you in your clinical guidance to reach for fetal diagnosis. 
              We are not legally liable for any clinical error of judgment on the clinician or user side or any possible deficiencies in ScanOFe.
            </p>
          </div>
          
          {/* Download PDF button */}
          {/* {pdfBlob && (
  <div className="mt-6 flex justify-center w-full">
    <a 
      href={URL.createObjectURL(pdfBlob)} 
      download={`${name.replace(/\s+/g, '_')}_report.pdf`}
      className="bg-[#052e5c] text-white px-4 py-2 rounded hover:bg-blue-800"
    >
      Download PDF
    </a>
  </div>
)} */}




      {showTemplateDialog && (
        <TemplateDialog onClose={() => setShowTemplateDialog(false)} />
      )}
        </div>
      );
    };

    return (
        <div className="w-full h-full flex">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-600">Generating report...</p>
            </div>
          ) : showTemplateDialog ? (
            <div className="w-full h-full">
              
              <TemplateDialog onClose={handleCloseTemplate} />
            </div>
          ) : showSummary && pdfData ? (
            <div className="w-full">
              <PdfView />
              <div className="flex justify-end p-2 gap-2">
  {pdfBlob && (
    <a 
      href={URL.createObjectURL(pdfBlob)} 
      download={`${name.replace(/\s+/g, '_')}_report.pdf`}
      className="bg-[#052e5c] text-white px-4 py-2 rounded hover:bg-blue-800"
    >
      Download PDF
    </a>
  )}
  
  <button
    className="bg-[#052e5c] text-white px-4 py-2 rounded hover:bg-blue-800"
    onClick={handleShowTemplate}
  >
    Show Template
  </button>
</div>

            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-600">No report data available</p>
            </div>
          )}
        </div>
      );
};

export default Summary;