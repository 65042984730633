import React, { useState, useEffect, useRef, Profiler } from "react";
import TemplateService from "../components/TemplateService";
// import "./templateCheckbox.css";

const ToolbarButton = ({ onClick, title, children }) => (
  <button
    onClick={onClick}
    className="p-2 hover:bg-gray-100 rounded"
    title={title}
  >
    {children}
  </button>
);

const getCurrentProfileId = () => {
  const profile = localStorage.getItem("profileId");
  return profile ? JSON.parse(profile) : null;
};

const SpecialElementDialog = ({ element, onSave, onClose }) => {
  const [content, setContent] = useState(element.content);

  const handleSave = () => {
    onSave(element.id, content);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded shadow-lg w-4/5 max-w-lg md:w-3/4 lg:w-1/2 lg:max-w-2xl">
        <h2 className="text-lg font-bold mb-3">Edit {element.name}</h2>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className="w-full h-36 p-2 border rounded mb-3"
          placeholder={`Enter ${element.name} content...`}
        />
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-3 py-1.5 bg-blue-800 text-white rounded hover:bg-blue-900 text-sm"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-3 py-1.5 text-white bg-[#0d1838] border rounded hover:bg-[#0d1400] text-sm"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const TemplateDialog = ({ onClose }) => {
  const defaultSpecialElements = [
    { id: "signature", name: "Signature", content: "[Insert Signature]" },
    { id: "name", name: "Name", content: "[Insert Name]" },
    { id: "distinction", name: "Distinction", content: "[Insert Distinction]" },
    { id: "disclaimer", name: "Disclaimer", content: "[Insert Disclaimer]" },
  ];
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [isLoadingElements, setIsLoadingElements] = useState(true);
  const [selectedTemplates, setSelectedTemplates] = useState(new Set());
  const [predefinedTemplates, setPredefinedTemplates] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [newTemplate, setNewTemplate] = useState({ name: "", content: "" });
  const [customTemplates, setCustomTemplates] = useState([]);
  const [specialElements, setSpecialElements] = useState(
    defaultSpecialElements
  );
  const [isEditing, setIsEditing] = useState(false);

  const editorRef = useRef(null);
  const lastSelectionRef = useRef(null);

  const [showSpecialElementDialog, setShowSpecialElementDialog] =
    useState(false);
  const [currentSpecialElement, setCurrentSpecialElement] = useState(null);

  const fetchPredefinedTemplates = async () => {
    try {
      const templates = await TemplateService.getPredefinedTemplates();

      setPredefinedTemplates(templates);
    } catch (error) {
      console.error("Error fetching predefined templates:", error);
    }
  };

  const fetchCustomTemplates = async (profile_id) => {
    try {
      const templates = await TemplateService.getCustomTemplates(profile_id);
      setCustomTemplates(templates);
    } catch (error) {
      console.error("Error fetching custom templates:", error);
    }
  };
  const fetchSpecialelements = async (profile_id) => {
    try {
      const templates = await TemplateService.getSpecialElements(profile_id);
      setSpecialElements(templates);
    } catch (error) {
      console.error("Error fetching custom templates:", error);
    }
  };
  const handleAddOrUpdateTemplate = async () => {
    if (!newTemplate.name || !newTemplate.content) return;

    try {
      const profile_id = getCurrentProfileId();

      if (isEditing && newTemplate.id) {
        await TemplateService.updateTemplate(newTemplate.id, {
          name: newTemplate.name,
          content: newTemplate.content,
        });
      } else {
        await TemplateService.createOrUpdate({
          name: newTemplate.name,
          content: newTemplate.content,
        });
      }

      await fetchCustomTemplates(profile_id);

      setNewTemplate({ id: null, name: "", content: "" });
      setShowAddTemplate(false);
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving template:", error);
    }
  };

  const handleEditTemplate = async (templateId) => {
    const template = customTemplates.find((t) => t.id === templateId);
    if (template) {
      setNewTemplate({
        id: template.id,
        name: template.name,
        content: template.content,
      });
      setIsEditing(true);
      setShowAddTemplate(true);
    }
  };

  const handleTemplateSelect = (templateId) => {
    const template = [...predefinedTemplates, ...customTemplates].find(
      (t) => t.id === templateId
    );
    const specialElement = specialElements.find((e) => e.id === templateId);

    if (!template && !specialElement) return;

    const newSelectedTemplates = new Set(selectedTemplates);

    if (selectedTemplates.has(templateId)) {
      newSelectedTemplates.delete(templateId);
    } else {
      newSelectedTemplates.add(templateId);
    }

    setSelectedTemplates(newSelectedTemplates);

    // Update editor content with selected templates
    const allContent = Array.from(newSelectedTemplates)
      .map((id) => {
        const t = [...predefinedTemplates, ...customTemplates].find(
          (t) => t.id === id
        );
        const s = specialElements.find((e) => e.id === id);
        return t?.content || s?.content || "";
      })
      .filter(Boolean)
      .join("\n\n");

    if (editorRef.current) {
      editorRef.current.innerText = allContent;
      setEditorContent(allContent);
    }
  };

  const saveSelection = () => {
    if (!editorRef.current) return;

    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      if (editorRef.current.contains(range.commonAncestorContainer)) {
        lastSelectionRef.current = range.cloneRange();
      }
    }
  };

  const restoreSelection = () => {
    if (!editorRef.current || !lastSelectionRef.current) return;

    try {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(lastSelectionRef.current.cloneRange());
    } catch (error) {
      console.warn("Failed to restore selection:", error);
    }
  };

  const insertContentAtCursor = (content) => {
    if (!editorRef.current) return;

    const plainContent = content.replace(/<[^>]*>/g, "");

    const currentContent = editorRef.current.innerText;
    const selection = window.getSelection();

    let newContent = currentContent;

    if (currentContent) {
      newContent += "\n\n" + plainContent;
    } else {
      newContent = plainContent;
    }

    editorRef.current.innerText = newContent;

    // Set cursor at the end
    const range = document.createRange();
    range.selectNodeContents(editorRef.current);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);

    saveSelection();
    setEditorContent(newContent);
  };

  const execCommand = (command, value = null) => {
    document.execCommand(command, false, value);
    editorRef.current?.focus();
    saveSelection();
  };

  const handleEditorChange = () => {
    saveSelection();
    const newContent = editorRef.current?.innerText || "";
    setEditorContent(newContent);
  };

  const handleExport = () => {
    try {
      if (!editorRef.current?.innerText) {
        console.warn("No content to export");
        return;
      }

      const plainText = editorRef.current.innerText;
      const blob = new Blob([plainText], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "report.doc";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Export error:", error);
    }
  };

  const handleSpecialElementSelect = (elementId) => {
    const element = specialElements.find((e) => e.id === elementId);
    if (!element) return;

    if (!element.content || element.content.includes("[Insert")) {
      setCurrentSpecialElement(element);
      setShowSpecialElementDialog(true);
    } else {
      handleTemplateSelect(elementId);
    }
  };

  const handleEditSpecialElement = (elementId, event) => {
    event.stopPropagation();
    const element = specialElements.find((e) => e.id === elementId);
    if (element) {
      setCurrentSpecialElement(element);
      setShowSpecialElementDialog(true);
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      await TemplateService.deleteTemplate(templateId);
      const profile_id = getCurrentProfileId();
      await fetchCustomTemplates(profile_id);
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };
  const getElementName = (elementType) => {
    const names = {
      signature: "Signature",
      name: "Name",
      distinction: "Distinction",
      disclaimer: "Disclaimer",
    };
    return names[elementType] || elementType;
  };
  const handleSpecialElementSave = async (elementId, newContent) => {
    try {
      const profileId = getCurrentProfileId();
      // Fetch existing special elements to check if an entry already exists
      const existingElements = await TemplateService.getSpecialElements(
        profileId
      );

      console.log("Existing elements:", existingElements);
      console.log("Element ID being checked:", elementId);

      const existingElement = existingElements.find((e) => e.id === elementId);

      if (existingElement) {
        // If element exists, update it
        await TemplateService.updateSpecialElement(
          existingElement.specialElementId,
          { content: newContent }
        );

        // Fetch updated elements after save
        const updatedElements = await TemplateService.getSpecialElements(
          profileId
        );
        console.log("Updated elements:", updatedElements);

        // Map the updated elements to the local state format
        const mergedElements = specialElements.map((element) => {
          const updatedElement = updatedElements.find(
            (e) => e.id === element.id
          );
          return {
            ...element,
            content: updatedElement ? updatedElement.content : element.content,
          };
        });
        console.log("merged ", mergedElements);

        setSpecialElements(mergedElements);

        // Update editor content if the element was selected
        if (selectedTemplates.has(elementId)) {
          const newSelectedContent = Array.from(selectedTemplates)
            .map((id) => {
              const element = mergedElements.find((e) => e.id === id);
              return element?.content || "";
            })
            .filter(Boolean)
            .join("\n\n");

          editorRef.current.innerText = newSelectedContent;
          setEditorContent(newSelectedContent);
        }
      } else {
        // Save the new content for the special element
        await TemplateService.createSpecialelement({
          elementType: elementId,
          content: newContent,
        });

        // Fetch updated elements
        const profileData = getCurrentProfileId();
        const updatedElements = await TemplateService.getSpecialElements(
          profileData
        );

        // Update the specialElements state with the new content
        const mergedElements = specialElements.map((element) => {
          const updatedElement = updatedElements.find(
            (e) => e.id === element.id
          );
          if (updatedElement) {
            return {
              ...element,
              content: updatedElement.content,
            };
          }
          return element;
        });

        setSpecialElements(mergedElements);

        // If the element was selected, update the editor content
        if (selectedTemplates.has(elementId)) {
          const newSelectedContent = Array.from(selectedTemplates)
            .map((id) => {
              const element = mergedElements.find((e) => e.id === id);
              return element?.content || "";
            })
            .filter(Boolean)
            .join("\n\n");

          editorRef.current.innerText = newSelectedContent;
          setEditorContent(newSelectedContent);
        }
      }
    } catch (error) {
      console.error("Error saving special element:", error);
    }
  };

  const handleReset = () => {
    editorRef.current.innerText = "";
    setEditorContent("");

    setSelectedTemplates(new Set());
  };

  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      editor.addEventListener("mouseup", saveSelection);
      editor.addEventListener("keyup", saveSelection);
      editor.addEventListener("focus", restoreSelection);
    }

    return () => {
      if (editor) {
        editor.removeEventListener("mouseup", saveSelection);
        editor.removeEventListener("keyup", saveSelection);
        editor.removeEventListener("focus", restoreSelection);
      }
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingTemplates(true);
        const getCurrentProfileId = () => {
          const profile = localStorage.getItem("profileId");
          return profile ? JSON.parse(profile) : null;
        };
        const profileData = getCurrentProfileId();
        console.log("Current profile ID................:", profileData);
        const profile_id = getCurrentProfileId();
        await fetchCustomTemplates(profile_id);
        setIsLoadingTemplates(false);
        setIsLoadingElements(true);

        const elements = await TemplateService.getSpecialElements(profile_id);

        const mergedElements = defaultSpecialElements.map((defaultElement) => {
          const customElement = elements.find(
            (e) => e.id === defaultElement.id
          );
          return customElement || defaultElement;
        });
        setSpecialElements(mergedElements);
        setIsLoadingElements(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoadingTemplates(false);
        setIsLoadingElements(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const profile_id = getCurrentProfileId();
    fetchCustomTemplates(profile_id);
    fetchPredefinedTemplates();
  }, []);

  return (
    <div className="w-full h-full flex flex-col md:flex-row">
      {/* Templates sidebar */}
      <div className="w-full md:w-5/12 lg:w-1/3 border-b md:border-b-0 md:border-r border-gray-200 p-3 overflow-y-auto">
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-base font-bold ml-1">Templates</h2>
          <button
            onClick={() => {
              if (showAddTemplate && isEditing) {
                setNewTemplate({ id: null, name: "", content: "" });
                setIsEditing(false);
              }
              setShowAddTemplate(!showAddTemplate);
            }}
            className="px-2 py-1 text-white bg-[#0d1838] border rounded mr-2 text-xs"
          >
            {showAddTemplate ? "Cancel" : "Add Template"}
          </button>
        </div>

        {/* Add/Edit Template Form */}
        {showAddTemplate && (
          <div className="mb-3 p-2 border rounded bg-gray-50">
            <input
              type="text"
              placeholder="Template Name"
              value={newTemplate.name}
              onChange={(e) =>
                setNewTemplate({ ...newTemplate, name: e.target.value })
              }
              className="w-full mb-2 p-2 border rounded text-sm"
            />
            <textarea
              placeholder="Template Content"
              value={newTemplate.content}
              onChange={(e) =>
                setNewTemplate({ ...newTemplate, content: e.target.value })
              }
              className="w-full mb-2 p-2 border rounded h-24 text-sm"
            />
            <button
              onClick={handleAddOrUpdateTemplate}
              disabled={!newTemplate.name || !newTemplate.content}
              className="w-full px-3 py-1.5 bg-green-500 text-white rounded hover:bg-green-600 disabled:opacity-50 text-xs"
            >
              {isEditing ? "Update Template" : "Save Template"}
            </button>
          </div>
        )}

        {/* Predefined Templates Section */}
        <div className="mb-3">
          <div className="space-y-1">
            {predefinedTemplates.map((template) => (
              <div
                key={template.id}
                className="flex items-center p-2 rounded hover:bg-gray-100"
              >
                <label
                  className="custom-checkbox"
                  onClick={(e) => e.stopPropagation()}
                >
                  <input
                    type="checkbox"
                    checked={selectedTemplates.has(template.id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleTemplateSelect(template.id);
                    }}
                    className="mr-2"
                  />
                  <span className="checkmark"></span>
                  <span className="text-sm">{template.name}</span>
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* Custom Templates Section */}
        {customTemplates.length > 0 && (
          <div className="mb-3">
            <div className="space-y-1">
              {customTemplates.map((template) => (
                <div
                  key={template.id}
                  className="flex items-center justify-between p-2 rounded hover:bg-gray-100"
                >
                  <div className="flex items-center">
                    <label
                      className="custom-checkbox"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <input
                        type="checkbox"
                        checked={selectedTemplates.has(template.id)}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleTemplateSelect(template.id);
                        }}
                        className="mr-2"
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm truncate">{template.name}</span>
                    </label>
                  </div>
                  <div className="flex space-x-1">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditTemplate(template.id);
                      }}
                      className="px-2 py-0.5 text-xs text-white bg-[#0d1838] border rounded"
                      title="Edit Template"
                    >
                      Edit
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteTemplate(template.id);
                      }}
                      className="px-2 py-0.5 text-xs bg-red-500 text-white rounded hover:bg-red-600"
                      title="Delete Template"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="mt-auto">
          <div className="space-y-1">
            {specialElements.map((element) => (
              <div
                key={element.id}
                className="flex items-center p-2 rounded hover:bg-gray-100"
              >
                <div className="flex items-center">
                  <label
                    className="custom-checkbox flex-shrink-0"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      type="checkbox"
                      checked={selectedTemplates.has(element.id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleTemplateSelect(element.id);
                      }}
                      className="mr-2"
                    />
                    <span className="checkmark"></span>
                    <span className="text-sm">{element.name}</span>
                  </label>
                </div>
                <div className="ml-auto">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditSpecialElement(element.id, e);
                    }}
                    className="px-2 py-0.5 text-xs bg-gray-200 border border-[#0d1838] rounded hover:bg-gray-300"
                    title="Change content"
                  >
                    Change Content
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Editor area */}
      <div className="flex-1 p-3 flex flex-col h-full">
        <div className="flex justify-between items-center mb-3 flex-wrap">
          <h2 className="text-base font-bold mb-2 md:mb-2 lg:mb-0">Report Content</h2>
          <div className="flex flex-wrap gap-1 w-full md:w-auto justify-end">
            <button
              onClick={handleExport}
              disabled={!editorContent}
              className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600 disabled:opacity-50 text-xs"
            >
              Export as Word
            </button>
            <button
              onClick={handleReset}
              className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 text-xs"
            >
              Reset
            </button>
            <button
              onClick={onClose}
              className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-gray-600 text-xs"
            >
              Close
            </button> 
          </div>
        </div>

        <div className="border border-gray-200 rounded-lg mb-3 p-1 bg-white overflow-x-auto">
          <div className="flex items-center space-x-1 border-b border-gray-200 pb-1">
            <ToolbarButton
              onClick={() => execCommand("formatBlock", "h2")}
              title="Heading"
            >
              <span className="font-bold text-base">H</span>
            </ToolbarButton>
            <ToolbarButton onClick={() => execCommand("bold")} title="Bold">
              <span className="font-bold">B</span>
            </ToolbarButton>
            <ToolbarButton onClick={() => execCommand("italic")} title="Italic">
              <span className="italic">I</span>
            </ToolbarButton>
            <ToolbarButton
              onClick={() => execCommand("insertUnorderedList")}
              title="Bullet List"
            >
              <span>•</span>
            </ToolbarButton>
            <ToolbarButton
              onClick={() => execCommand("justifyLeft")}
              title="Align Left"
            >
              ⇤
            </ToolbarButton>
            <ToolbarButton
              onClick={() => execCommand("justifyCenter")}
              title="Align Center"
            >
              ⇔
            </ToolbarButton>
            <ToolbarButton
              onClick={() => execCommand("justifyRight")}
              title="Align Right"
            >
              ⇥
            </ToolbarButton>
          </div>
        </div>

        <div
          ref={editorRef}
          className="flex-1 border border-gray-300 p-3 overflow-y-auto bg-white rounded-lg whitespace-pre-wrap text-sm"
          contentEditable={true}
          onInput={handleEditorChange}
          style={{ maxHeight: "calc(100vh - 280px)" }}
        />
      </div>

      {showSpecialElementDialog && currentSpecialElement && (
        <SpecialElementDialog
          element={currentSpecialElement}
          onSave={handleSpecialElementSave}
          onClose={() => setShowSpecialElementDialog(false)}
        />
      )}
    </div>
  );
};

export default TemplateDialog;
